/* fonts */
$font_0: 'Open Sans';
$font_1: sans-serif;
$font_2: sans-serif!important;



/* basic colors */
$white: #fff;
$color_celeste_approx: #ccc;
$black: #000;




/* other colors */
$color_alabaster_approx: #f9f9f9;
$color_mercury_approx: #e3e3e3;
$color_vermilion_approx: #ff4e00;
$color_vermilion_approx_rgba: rgba(255,78,0,0.6);
$color_vermilion_approx_hover: #da4403;
$mine_shaft: #323232;

$color_alabaster_approx: #f9f9f9;
$color_mercury_approx: #e3e3e3;
$color_vermilion_approx: #ff4e00;
$black_10: rgba(0,0,0,0.1);
$white_10: rgba(255,255,255,0.1);
$white: #fff;
$color_celeste_approx: #ccc;
$black: #000;
$color_fuscous_gray_approx: #525252;
$color_dove_gray_approx: #696969;
$color_atlantis_approx: #a6c040;
$mine_shaft: #323232;
$color_gray_nurse_approx: #eaeaea;
$color_silver_chalice_approx: #aaa;
$color_shark_approx: #242424;
$color_alto_approx: #dadada;
$color_suva_gray_approx: #888;
$color_grenadier_approx: #da4402;
$color_persian_red_approx: #cd3d27;
$color_trinidad_approx: #e34c0a;
$color_mine_shaft_approx: #333333;
$color_gallery_approx: #f0f0f0;
$color_storm_dust_approx: #646464;
$color_nobel_approx: #b6b6b6;
$color_carrot_orange_approx: #f68526;
$color_quill_gray_approx: #d3d2d2;
$color_limeade_approx: #6e9e00;
$color_cardinal_approx: #cc2738;
$color_desert_storm_approx: #f8f8f8;
$color_chicago_approx: #5b5b5b;
$color_concrete_approx: #f3f3f3;
$color_chateau_green_approx: #39b54a;
$color_monza_approx: #dd1818;
$color_stack_approx: #8b8b8b;
$color_orange_approx: #f36b21;
$color_iron_approx: #d7d7d7;
$color_vida_loca_approx: #5a801b;
$color_willow_brook_approx: #dff0d8;
$color_beryl_green_approx: #d6e9c6;
$color_oyster_pink_approx: #ebccd1;
$color_vanilla_ice_approx: #f2dede;
$color_guardsman_red_approx: #cc0000;
$color_steel_blue_approx: #4985b2;
$color_link_water_approx: #d9edf7;
$color_charlotte_approx: #bce8f1;
$white_80: rgba(255, 255, 255, 0.8);
$color_wild_sand_approx: #f5f5f5;
$color_aqua_haze_approx: #ebf4f7;
$color_glacier_approx: #75a3c7;
$color_ziggurat_approx: #bedee8;
$color_hit_pink_approx: #ffa780;
$early_dawn: #fff9e6;
$color_saffron_mango_approx: #ffbe57;
$color_cornflower_approx: #90bee3;
$color_burning_orange_approx: #ff763a;
$color_cloud_approx: #c4c4c4;
$black_0: rgba(0,0,0,0.0);
$color_koromiko_approx: #ffc261;
$color_mountain_mist_approx: #999;
$color_silver_sand_approx: #c2c2c2;
$color_morocco_brown_approx: #412001;
$color_peru_tan_approx: #883000;
$white_50: rgba(255,255,255,0.5);
$black_50: rgba(0,0,0,0.5);
$dialog_buttonset: #e0e0e0;
$green: #39b54a;
$red: #E82323;
$important_alert: #F5CEAE;
$color_atlantis_approx: #a6c040;
$color_atlantis_approx_hover: #eef2dc;
$color_koromiko_approx: #ffc261;
$color_koromiko_approx_hover: #fff2dd;
$color_pippin_approx: #ffebeb;

/* images */

$url_0: url(../images/spotello_logo.svg);
$url_1: url(../images/spotello_logo_orange.svg);
$url_2: url(../images/person.svg);
$url_3: url(../images/login.svg);
$url_4: url(../images/down-arrow_grey.svg);
$url_5: url(../images/up-arrow_grey.svg);
$url_35: url(../img/sprite-skin-flat.png);
$url_22: url(../images/coffee-break.svg);
$url_23: url(../images/coffee-service.svg);
$url_24: url(../images/dinner.svg);
$url_25: url(../images/loop-white.svg);


$url_100: url(../images/map_mini.jpg);
$url_101: url(../images/map_mini_dark.jpg);
$url_102: url(../images/close.svg);
$url_40: url(../images/person_white.svg);
$url_19: url(../images/okwhite.png);
$url_20: url(../images/arrow-left-blue.svg);
$url_21: url(../images/arrow-right-blue.svg);
$url_47: url(../images/_lastminute2-icon.png);
$url_45: url(../images/_free2-icon.png);
$url_44: url(../images/_confirm2-icon.png);
$url_28: url(../images/i.svg);
$url_31: url(../images/close.svg);
$url_6: url(../images/close-grey.svg);
$url_32: url(../images/arrow-up2.svg);
$url_33: url(../images/arrow-up1.svg);
$url_34: url(../images/arrow-down2.svg);
$url_42: url(../images/_confirm-icon.png);
$url_46: url(../images/_lastminute-icon.png);
$url_30: url(../images/_free-icon.png);
$url_29: url(../images/map-icon.png);
$url_26: url(../images/rating-grey.png);
$url_27: url(../images/rating-blue.png);
$url_41: url(../images/plus-icon-btn.png);
$url_43: url(../images/trash-icon.png);
$url_13: url(../images/add-icon.png);
$url_14: url(../images/remove-icon.png);
$url_38: url(../images/close_white.png);
$url_36: url(../images/settings-icon.png);
$url_39: url(../images/panel-settings-icon.png);
$url_48: url(../images/menu-sidebar-icon.png);
$url_37: url(../images/rolling.svg);

$url_49: url(../images/admin/icons/sort-icon.png);
$url_50: url(../images/admin/icons/sort-down-icon.png);
$url_51: url(../images/admin/icons/sort-up-icon.png);
$url_53: url(../images/admin/icons/delete-icon.png);
$url_52: url(../images/admin/icons/plus-icon.png);
$url_61: url(../images/download-icon-white.png);
$url_62: url(../images/powered_by_sr.svg);
$url_63: url(../images/cart-icon.png);

$url_64: url(../images/slide_1.jpg);
$url_65: url(../images/choose_bg.png);
$url_66: url(../images/fold_icon.png);