// ------------------------------------SECTION HEADER ---------------------------------------- //

li.cart {
	background: $url_63 no-repeat;
	width: 25px;
	div {
		width: 25px;
		height: 25px;
		position: relative;
	}
	span {
		display: inline-block;
		width: 12px;
		height: 12px;
		background:$mine_shaft;
		color: white;
		border-radius: 100%;
		text-align: center;
		font-size: 9px;
		margin-left: 18px;
	}
}

// PANEL TOP HEADER //

.rwd-only {
	display: none!important;
}

.panel-top-header {
	background: #fff;
	height: 88px;
	font-size: 12px;
	color: #333;
	letter-spacing: 1px;
	font-weight: 600;
	padding: 12px 15px;
	color: #646464;
	  	a {
	    	color: #333;
	    &:hover {
	      	color: #000;
	      	text-decoration: none;
	    }
	}
	.top-tittle {
	    width: auto;
	    font-size: 28px;
	    font-weight: bold;
	    color: #333333;
	    display: inline;
	    float: left;
	    .name { 
	    	line-height: 60px; 
	    }
	}
	.top-tittle2 {
	    width: auto;
	    font-size: 13px;
	    font-weight: 100;
	    padding: 10px 20px 35px 0;
	    position: relative;
	    display: inline;
	    float: left;
	    img.logo {
	      position: absolute;
	      bottom: 8px;
	    }
	    &.noline {
			border-left:0;
			margin-left:0;
		}
	}
	.top-contact {
        float: right;
        text-align: right;
        font-size: 12px;
        font-weight: 600;
        padding: 15px 0 0 0;
        letter-spacing: 0;
        img {
        	margin-right: 10px;
        }
        span.phone {
          font-weight: normal;
          display: block;
        }
    }
}

//PANEL MIDDLE HEADER

.menugrey-container { 
	width: 100%; 
	background: #6c6c6c; 
	height: auto;
	z-index: 100;
	&.f-nav {
		width: 100%;
		position:fixed; 
		-webkit-transition: all 0.2s linear; 
		-moz-transition: all 0.2s linear; 
		-ms-transition: all 0.2s linear; 
		-o-transition: all 0.2s linear; 
		transition: all 0.2s linear; 
		z-index: 100;
	}
	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

.panel-middle-header {
	background: $white;
	height: 88px;
	font-size: 12px;
	color: $header_top_font;
	letter-spacing: 1px;
	font-weight: 600;
	padding: 12px 15px;
	color: $color_storm_dust_approx;

	.top-tittle {
	width: auto;
	font-size: 28px;
	font-weight: bold;
	color: $header_top_font;
	display: inline;
	float: left;
		.name {
			line-height: 60px;
		}
	}

	.top-tittle2 {
	width: auto;
	font-size: 13px;
	font-weight: 100;
	padding: 10px 20px 35px 15px;
	border-left: 1px solid $color_alto_approx;
	margin-left: 15px;
	position: relative;
	display: inline;
	float: left;
		img.logo {
			position: absolute;
			bottom: 8px;
			left: 20px;
		}
		.logo {
			padding-left: 0;
		}
	}

	.object-selector-container {
	float: right;

		.object_selector {
		width: 200px;
		position: absolute;
		right: 20px;
		top: 14px;
		background: $url_3 no-repeat;
		background-size: 8px 5px;
		background-position: top 15px right 10px;
		}
	}

}

// NAV

/* .menugrey-container {
	width: 100%;
	background: $menu_bg;
	height: auto;
	z-index: 100;
	nav {
		height: 85px;
		background: $menu_bg;
		.container {
			padding-left: 0;
			padding-right: 0;
		}
		&.p-menu {
			background: $menu_bg;
			height: 45px;
			width: 100%;
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: inline-table;
			li {
				color: #fff;
				text-transform: uppercase;
				font-weight: 500;
				letter-spacing: 0px;
				height: 45px;
				line-height: 45px;
				display: table-cell;
				a {
					color: #fff;
					text-decoration: none;
					width: 100%;
					height: 45px;
					padding: 14px 18px;
					font-size: 12px;
					&:hover {
						background: $main_color_vivid;
						padding: 14px 18px;
						width: 100%;
						height: 45px;
						line-height: 45px;
					}
					&.active {
						background: $main_color_vivid;
						padding: 14px 18px;
						width: 100%;
						height: 45px;
						line-height: 45px;
					}
				}
			}
			&.main {
				li {
					border-left: 1px solid #757575;
					&:last-child {
						border-right: 1px solid #757575;
					}
				}
			}
		}
		ul.p-useroptions {
			float: right;
			height: 45px;
			margin-bottom: 0;
			line-height: 45px;
			li {
				display: inline-block; 
				width: auto;
				float: left;
				&.root_link {
					width: 70px;
					line-height: 13px;
					padding-top: 8px;
					margin-right: 5px;
					a {
						color: #fff;
						font-size: 11px;
						padding: 0;
						&:hover {
							line-height: 13px;
							background: none;
						}
					}
				}
				&.username {
					padding-right: 15px;
					text-transform: none!important;
					color: #fff;
					a {
						color: #fff;
						text-decoration: underline;
						padding-left: 20px;
					}
				}
				&.username-min { 
					display: none;
				}
				&.settings {
					a {
						padding: 14px 9px;
						&:hover {
							background: none;
						}
					}
				}
				&.logout {
					color: #fff;
					a {
						padding: 14px 15px;
						text-transform: initial;
						color: #fff;	
						line-height: 45px;
						&:hover {
							background: none;
						}
					}
				}
				&.cart { 
					background: $url_60 no-repeat; 
					width: 54px; 
					height: 45px; 
					background-position: top 14px left 14px; 
					cursor: pointer; 
					a {
						padding: 14px 8px 14px 14px;
						&:hover {
							background: none;
						}
					}
					.round { 
						background: #f68526;
						width: 17px; 
						height: 17px; 
						line-height: 17px; 
						text-align: center; 
						border-radius: 100%; 
						font-size: 10px; 
						display: inline-block;
						margin-left: 15px;
						color: $white;
						padding: 0;
						font-weight: normal;
					}
				}
			}
			.menu-only-rwd {
				display: none;
			}
			.menu-settings {
				background: $menu_bg;
				ul {
					display: block;
					li {
						display: block;
						float: none;
						height: auto;
						text-transform: none!important;
						a {
							display: block;
						    padding: 3px 20px;
						    clear: both;
						    font-weight: normal;
						    line-height: 1.42857143;
						    color: #333;
						    white-space: nowrap;
						    font-size: 12px;
						    height: auto;
						    color: $white;
						    &:hover {
								background: $main_color_vivid!important;	
							}
						}
						&.divider {
							height: 1px;
						}
					}
				}
			}
		}
	}
} */
// FILTER TABLE

.p-filters {
  	padding-left: 20px;
  	margin-bottom:10px;
  	li {
	    display: inline;
	    margin-right: 20px;
	    font-weight: 500;
	    font-size: 12px;
    	a {
	      cursor: pointer;
	      color: #5b5b5b;
	      &:hover {
	        color: #f68526;
	        text-decoration: none;
	      }
    	}
  	}
}

// SUBMENU

.p-submenu {
	padding: 0;
	margin: 0;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-table;
			margin: 0 4px;
			color: $color_pumice_approx;
			a {
				color: $main_font;
				padding: 12px;
				border-bottom: 3px solid $color_pumice_approx;
				display: block;
				&.current {
					color: $main_color_vivid;
					border-bottom: 3px solid $main_color_vivid;
				}
			}
			&:hover a {
				display: inline-table;
				border-bottom: 3px solid $main_color_vivid;
				text-decoration: none;
				color: $main_color_vivid;
				padding: 12px;
			}
		}
	}
}

.start_content { 
	background: #f8f8f8; 
	height: 100%; 
	overflow: auto; 
}

// PAGINATION

.pagination-container {
  	width: 100%;
  	text-align: center;
  	font-size: 11px;
  	.pagination {
	    margin-left: auto;
	    margin-right: auto;
	    width: auto;
	    display: inline-block;
	    a {
	      	padding: 5px 10px;
	      	background: #fff;
	      	border: 1px solid #e9e9e9;
	      	&:hover {
	        	background: $main_color_vivid;
	       		border: 1px solid $main_color_vivid;
	        	text-decoration: none;
	        	color: #fff;
	      	}
	    }
	    span {
	      	padding: 5px 10px;
	      	border: 1px solid #e9e9e9;
	      	background: $table_font;
	      	color: $white;
	      	&.no-active {
	      		padding: 5px 10px;
	      		background: #fff;
	      		border: 1px solid #e9e9e9;
	      		opacity: 0.5;
	      		color: $second_font;
	      	}
	    }
  	}
}

// ------------------------------------SECTION CONTENT ---------------------------------------- //

// MAIN TABLES

table.default_form {
	margin:10px;
}

td.actions {
		font-weight: normal;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
}

.action-list-menu-init > ul > li {
	display: inline-block;
	vertical-align: middle;
	&.none {
		display: none;
	}
}

td.tlabel {
    font-size: 13px;
    font-weight: normal;
    line-height: 36px;
    text-align: right;
    padding: 4px;
}

td.tinformation {
    padding-left: 10px;
    font-size: 12px;
}

.action-list-menu-releaser {
	background: $url_39 no-repeat scroll center center transparent;
	height: 19px;
	margin: auto;
	padding: 0.5em 0.75em;
	position: relative;
	width: 30px;
}
.action-list-menu {
	min-width: 165px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
	.action-list-menu-releaser {
		position: relative;
		border-bottom: 0 none;
		margin-right: 0;
		z-index: 101;
	}
	> ul {
		position: relative;
		text-align: left;
		top: -1px;
		> li {
			border-color: $color_alto_approx;
			border-style: solid;
			border-width: 1px 0 0;
			cursor: pointer;
			line-height: 2.25em;
			min-height: 2.25em;
			min-width: 170px;
			padding: 0 0.5em;
			color: $white;
			&:first-child {
				border: 0 none;
			}
			> a {
				color: $white;
				display: block;
				text-decoration: none;
				white-space: nowrap;
				> img {
					margin: 0 0.5em;
					vertical-align: middle;
				}
			}
			> img {
				margin: 0 0.5em;
				vertical-align: middle;
			}
			&:hover a {
				color: $action_font_hover;
			}
			&.no-hover:hover {
				background-color: transparent;
			}
		}
	}
	ul.pmenu-ls {
		background: $action_bg;
		border-radius: 5px;
		li {
			border: none;
			padding: 3px 9px;
		}
	}
}

// FORMS

.input-wrapper {
	position:relative;
	float:left;
	width:18px;
	height:18px;
	margin:0 4px 0 0;
	clear: both;
	input[type="checkbox"] {
		opacity: 0;
		z-index: 2;
		position:absolute;
		width: 18px;
		height: 18px;
		margin: 0;
		cursor: pointer;
	}
	input[type="checkbox"] + .checklabel {
		width:18px;
		height: 18px;
		position:absolute;
		top: 0;
		left: 0;
		background:$white;
		z-index: 1;
		border: 1px solid $border;
	}
	input[type="checkbox"]:checked + .checklabel {
		width:18px;
		height: 18px;
		position:absolute;
		top: 0;
		left: 0;
		background:$green $url_19 no-repeat;
		background-size: 12px 9px;
		background-position: center;
		z-index: 1;
		border: 1px solid $green;
	}
}

// TABS

.ui-tabs {
	.ui-tabs-nav {
		background: $white;
		border-radius: 0;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border:0;
		border-bottom: 1px solid $p_border;
		padding: 0;
		margin: 0;
		li {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			background: $white;
			border: 0;
			margin: 0;
			border-right: 1px solid $p_border;
			a.ui-tabs-anchor {
				padding: 16px;
				font-size: 13px;
				letter-spacing: 1px;
				color: $main_font;
			}
			&.ui-state-active {
				background: $main_color_vivid;
				a.ui-tabs-anchor {
					color: $white;
				}
			}
			&.ui-tabs-active {
				padding-bottom: 0;
			}
			&.tab-title-light a.ui-tabs-anchor {
				padding: 16px;
				font-size: 13px;
			}
		}

		.tab-title-light {
			margin: 0;
			border-right: 1px solid $p_border;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
		}
	}
	.tab-header-light {
		padding: 0;
	}
	.tab-content-light {
		background: $white;
	}
}

// FILTERS

.static_filter_icons {
	display: inline-block;
	margin-top: 9px;
    margin-left: 10px;
    color: #f68526;
}

.date_filter_wrapper {
	display: inline-block;
	float: right;
	form label {
		font-size: 12px;
		font-weight: normal;
		.date_filter_title {
			float: left;
			display: inline-block;
			line-height:34px;
			margin-right: 5px;
			font-size:16px;
		}
		.date_filter_title2 {
			display: none;
		}
	}
}

.filter_content {
	input.range {
	width: 100px;
	margin-right:5px;
	float: left;
	}
	label .wlabel {
		display:block;
		width:100%;
		clear:both;
	}
}

.dd_icons {
	.ddTitle {
		.ddTitleText {
			padding:0 15px 0 0;
		}
	}
	.ddChild {
		box-shadow:none;
		border-radius:0;
		width:130px!important;
		ul {
			li {
				.ddlabel {
					
				}
				&:hover {
					border-radius:0;
				}
			}
		}
	}
	&:focus {
		text-decoration:none;
		outline: none;
	}
	&:hover {
		text-decoration:none;
	}
}

.ddcommon {
	font-size: 12px;
	width: auto!important;
	border-radius: 0;
	margin-right: 5px;
	margin-left: 5px;
	&.dd {
		width: auto!important;
		.ddTitle {
			height: 34px;
			background: $white;
			.ddTitleText {
			padding: 8px 27px 8px 5px;
			color: $main_font;
			}
		}
		.ddArrow { 
			width:8px;
			height:5px; 
			background:$url_34 no-repeat;
			background-position: center;
			background-size: 8px 5px;
			top: 45%;
			right: 6px;
		}
		.divider {
			display: none;
		}
		.ddChild {
			border-radius: 0;
			box-shadow: none;
			width: auto;
			min-width: 150px;
		}
		&:focus {
			outline:none;
		}
	}
	&.dd_description {
		.ddTitle {
			height: 34px;
			background: none;
			.ddTitleText {
				padding: 8px 27px 8px 5px;
			}
			.ddArrow { 
				width:8px;
				height:5px; 
				background:$url_34 no-repeat;
				background-position: center;
				background-size: 8px 5px;
				top: 45%;
				right: 6px;
			}
		}
		.divider {
			display: none;
		}
		.ddChild {
			border-radius: 0;
			box-shadow: none;
			width: auto;
			min-width: 150px;
		}
		&:focus {
			outline:none;
		}
	}
	&.dd_icons {
		.ddTitle {
			height: 34px;
			background: none;
			width: 45px;
			.ddTitleText {
			padding: 8px 27px 8px 5px;
			}
		}
		.ddArrow { 
			width:8px;
			height:5px; 
			background:$url_34 no-repeat;
			background-position: center;
			background-size: 8px 5px;
			top: 45%;
			right: 6px;
		}
		.divider {
			display: none;
		}
		.ddChild {
			border-radius: 0;
			box-shadow: none;
			width: auto;
			min-width: 150px;
		}
		&:focus {
			outline:none;
		}
	}
}

// filter invoices

#invoice-filter-holder .floatLeft {
	&:nth-child(1) label {
		width: 240px;
	}
	&:nth-child(2) label {
		width: 200px;
	}
	&:nth-child(3) label {
		width: 210px;
		margin-right: 0;
	}
	&:nth-child(4) label {
		width: 210px;
	}
	/* &:nth-child(5) label {
		width: 210px;
		margin-right: 0;
	}
	&:nth-child(6) label {
		width: 210px;
	} */
}

//filter orders

#order-filter-holder .floatLeft:nth-child(1) label {
	width: 230px;
}

//DIALOG 

.ui-dialog {
	/* color: $main_font;
	border:1px solid #000;
	border-radius: 0;
	padding: 0;
	max-width: 90%!important;
	.ui-dialog-titlebar {
		border-radius: 0;
		background: $p_header-top;
		color: $white;
		padding: 8px 20px;
		font-weight: normal;
		letter-spacing: 1px;
		font-size: 18px;
		border: 0;
	}
	.ui-dialog-titlebar-close {
		background: $url_102 no-repeat;
		background-position: center;
		background-size: 8px 8px;
		cursor: pointer;
		border: 0;
		border-radius: 0;
		float: right;
		span {
			display: none;
		}
		&:hover {
			background: $url_102 no-repeat;
			background-position: center;
			background-size: 8px 8px;
			cursor: pointer;
			border: 0;
			border-radius: 0;
			float: right;
		}
	} */
	.ui-dialog-titlebar-minimize {
		background: $url_21 no-repeat;
		background-position: center;
		background-size: 8px 8px;
		cursor: pointer;
		border: 0;
		border-radius: 0;
		width: 20px;
		height: 20px;
		span.ui-icon-minus {
			background: none;
			margin: 0;
		}
	}
	.ui-dialog-content {
		font-size: 12px;
		float: none;
		overflow-y: auto;
		overflow-x: hidden;
		//height: 400px!important;
		.module {
			.module_content {
				margin: 10px;
				fieldset.notice {
					label {
						font-size: 15px;
						font-weight: normal;
						float: left;
					}
					span.error-message {
						clear: both;
						float: left;
					}
					textarea.form-textarea {
						width: 100%;
						font-family: $font_opensans, $font_backup;
						font-size: 12px;
						border: 1px solid $p_header;
					}
				}
			}
			&.invoices {
				textarea {
					margin:0;
				}
			}
		}
		&.cancel {
			padding:10px!important;
		}
	}
	.ui-dialog-buttonpane {
		padding: 0;
		background: $p_bg;
		.ui-dialog-buttonset {
			span {
				background: $mine_shaft;
				color: $white;
			}
			button {
				border-radius: 0;
				border: 0;
				box-shadow: none;
			}
		}
	}
	footer {
		overflow:auto;
		margin:0;
		padding: 10px 0;
	}
}

// DATEPICKER

.ui-datepicker {
		width: 280px;
    	height: auto;
    	margin: 5px auto 0;
    	display: none;
		border-radius: 0;
		border: 1px solid $color_iron_approx;
		background: $white;
		color: $color_gravel_approx;
		font-family: $font_opensans, $font_backup;
		font-size: 12px;
		padding: 0;
		.ui-datepicker-header {
			position: relative;
			padding: 15px 0;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			border: 0;
			background: $dp_header;
			color: $white;
			font-weight: normal;
			font-size: 13px;
			text-transform: uppercase;
			a {
				&.ui-datepicker-prev {
					position: absolute;
					top: 12px;
					width: 25px;
					height: 25px;
					background: $url_20 no-repeat;
					background-position: center;
					background-size: 18px;
					border: 0;
					left: 0;
					cursor:pointer;
					span {
						display: none;
						position: absolute;
						margin: 0;
						top: 0;
						left: 0;
						width: 25px;
						height: 25px;
					}
				}
				&.ui-datepicker-next {
					position: absolute;
					top: 12px;
					width: 25px;
					height: 25px;
					background: $url_21 no-repeat;
					background-position: center;
					background-size: 18px;
					border: 0;
					right: 0;
					cursor:pointer;
					span {
						display: none;
						position: absolute;
						margin: 0;
						top: 0;
						left: 0;
						width: 25px;
						height: 25px;
					}
				}
				&.ui-datepicker-prev-hover {
					background: $url_20 no-repeat;
					background-position: center;
					background-size: 18px;
					border: 0;
					margin: 0;
					span {
						display: block;
						position: absolute;
						margin: 0;
						background: none;
						width: 25px;
						height: 25px;
					}
				}
				&.ui-datepicker-next-hover {
					background: $url_21 no-repeat;
					background-position: center;
					background-size: 18px;
					border: 0;
					margin: 0;
					span {
						display: block;
						position: absolute;
						margin: 0;
						background: none;
						width: 25px;
						height: 25px;
					}
				}
			}
		}
		table.ui-datepicker-calendar {
			font-size: 12px;
			thead {
			    background: #242424;
			    color: #8592a3;
			    text-transform: uppercase;
			    border-top: 1px solid #63626a;
			    th {
			    	padding: .7em .3em;
				    text-align: center;
				    font-weight: normal;
				    border: 0;
			    }
			}
			a {
				&.ui-state-default {
					border: 0;
					background: none;
					text-align: center;
					display: block;
					text-decoration: none;
					padding: 6px 3px;
				}
				&.ui-state-active {
					background: $main_color_vivid;
					color: $white;
				}
			}
		}
    	a {
    		text-decoration: none;
    	}
    	table {
    		width:100%;
    		margin:0;
    		a.ui-state-default, span.ui-state-default {
    			padding:7px 3px;
    			border: 0;
    			text-align: center;
    		}
    		span.ui-state-default {
    			background: rgba(241, 241, 241, 0.5);
    		}
    		thead {
    			background: #f9f9f9;
    		}
    	}
    	tbody td {
    		padding: 0;
    		border-right: 1px solid #f1f1f1;
    		width: 39px;
    		&.ui-datepicker-unselectable {
    			opacity:0.5;
    		}
    		&.ui-state-highlight {
    			border:0;
    			border-right: 1px solid #f1f1f1;
    			background: rgba(246, 133, 38, 0.5);
    			a {
    				color: #fff;
    			}
    		}
    		&.ui-datepicker-week-end {
    			background: rgba(246, 133, 38, 0.07);
    		}
    		&.ui-datepicker-week-end.ui-state-highlight {
    			background: rgba(246, 133, 38, 0.5);
    		} 
		}
		tbody td:last-child {
		    border-right: 0px;
		}
		tbody tr {
		    border-bottom: 1px solid #f1f1f1;
		}
		tbody tr:last-child {
		    border-bottom: 0px;
		}
	}

// stare
/* .ui-datepicker {
	display: none;
	border-radius: 0;
	border: 1px solid $color_iron_approx;
	background: $white;
	color: $color_gravel_approx;
	font-family: $font_opensans, $font_backup;
	font-size: 12px;
	padding: 0;
	width: 250px;
	.ui-datepicker-header {
		position: relative;
		padding: 15px 0;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		border: 0;
		background: $dp_header;
		color: $white;
		font-weight: normal;
		font-size: 13px;
		text-transform: uppercase;
		a {
			&.ui-datepicker-prev {
				position: absolute;
				top: 12px;
				width: 25px;
				height: 25px;
				background: $url_28 no-repeat;
				background-position: center;
				border: 0;
				span {
					display: block;
					position: absolute;
					margin: 0;
					top: 0;
					left: 0;
					width: 25px;
					height: 25px;
				}
			}
			&.ui-datepicker-next {
				position: absolute;
				top: 12px;
				width: 25px;
				height: 25px;
				background: $url_29 no-repeat;
				background-position: center;
				border: 0;
				span {
					display: block;
					position: absolute;
					margin: 0;
					top: 0;
					left: 0;
					width: 25px;
					height: 25px;
				}
			}
			&.ui-datepicker-prev-hover {
				background: $url_28 no-repeat;
				background-position: center;
				border: 0;
				margin: 0;
				span {
					display: block;
					position: absolute;
					margin: 0;
					background: none;
					width: 25px;
					height: 25px;
				}
			}
			&.ui-datepicker-next-hover {
				background: $url_29 no-repeat;
				background-position: center;
				border: 0;
				margin: 0;
				span {
					display: block;
					position: absolute;
					margin: 0;
					background: none;
					width: 25px;
					height: 25px;
				}
			}
		}
	}
	table.ui-datepicker-calendar {
		font-size: 12px;
		thead {
		    background: #242424;
		    color: #8592a3;
		    text-transform: uppercase;
		    border-top: 1px solid #63626a;
		    th {
		    	padding: .7em .3em;
			    text-align: center;
			    font-weight: normal;
			    border: 0;
		    }
		}
		a {
			&.ui-state-default {
				border: 0;
				background: none;
				text-align: center;
				display: block;
				text-decoration: none;
				padding: 6px 3px;
			}
			&.ui-state-active {
				background: $main_color_vivid;
				color: $white;
			}
		}
	}
} */

// REZERWACJE

// podgląd zamówienia, podgląd rezerwacji

p.b_title {
	padding: 10px;
	background: $tr_hover;
	float: left;
	width: 100%;
	font-size: 14px;
	margin-bottom: 5px;
}

/* .order_details-wrap, .booking_parent-wrap, .booking_details-wrap {
	padding: 0 5px 15px 5px;
	float:left;
	width: 100%;
	div {
		float: left;
	}
	.actions {
		border-bottom: 1px solid $p_header;
		float: left;
		width: 100%;
		ul {
			padding-left: 0;
			margin-bottom: 8px;
			li {
				display: inline;
				padding: 5px;
				a:hover {
					color: $main_color_vivid;
				}
			}
		}
	}
	.booking_information {
	list-style-type: none;
	padding-left: 0;
	width: 900px;
	float: left;
		li {
			&.column {
				float: left;
				width: 33%;
				list-style-type: none;
				border-left: 1px solid $p_header;
				&:first-child {
					border-left:0;
				}
			}
			&.booking_comment {
				float: right;
			}
			ul {
				padding-left: 15px;
				list-style-type: none;
				li {
					padding: 2px 0;
				}
			}
		}
		li.line {
			border-bottom: 1px solid $p_header;
			margin-bottom: 5px;
			padding-bottom: 5px;
		}
		h4 {
			padding: 0 10px;
		}
		ul.information {
			list-style-type: none;
			li {
				text-align: left;
				font-size: 12px;
				.not_confirmed {
					color: $no;
				}
				.unpaid {
					color: $no;
					font-weight:bold;
				}
				.label {
					color: $second_font;
					padding: 0;
					font-size: 12px;
				}
				.confirmed {
					color: $yes;
				}
			}
		}
	}
} */

// edycja rezerwacji

#reservation-edit-portlet {
	#reservation-edit-holder {
		padding:3px 10px;
		fieldset#edit_booking {
			border-radius:0;
			border:1px solid $p_border;
			padding: 0 0 10px 0;
			table {
				border-radius:0;
				color:$main_font;
				td.label {
					line-height: 36px;
					text-align: right;
					padding-right: 10px;
					display: block;
					color: $mine_shaft;
					font-weight: normal;
					font-size: 12px;
					&.tooltip {
						display:block;
						position: static;
						opacity:1;
						text-align: right;
						font-family: $font_opensans, $font_backup;			
					}
				}
				tr.booking_name {
					input {
						width:400px;
					}
				}
				tr.room_id {
					select {
						width:250px;
					}
				}
			}
			input.submit {
				margin-left: 10px;
			}
		}
	}
}

// SALE I OBIEKTY

.objects_container {
	padding-left: 0;
	padding-right: 0;
	.plist {
		padding-left: 0;
		padding-right: 0;
		margin-top: 0;
	}
}

// 1. LISTA OBIEKTOW

#objects-list-portlet {

	.c-light {
		padding:0;
	}
	.panel-table {
		margin: 0;
	}
	
	.list-container {
		tr:hover {
		background: $tr_hover;
		}
		td {
			background: transparent;
		}
		td.p-objname a {
		font-size: 15px;
		color: $table_font;
			&.save-filter.menu_edit {
				background: none;
				font-size: 11px;
				padding-left: 0;
				display: none;
			}
		}
		img.p-objmin {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			margin: 3px;
		}
		.sw-bg {
			display: table-cell;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			float: right;
			background: $white;
			border: 1px solid $p_border;
			margin-right: 3px;
			.switcher {
				background: $table_header;
				border-radius:100%;
				border:1px solid $white;
				width: 18px;
				height: 18px;
			}
		}
		tr.on {
			background: $tr_hover;
			.sw-bg {
				display: table-cell;
				vertical-align: middle;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				float: right;
				background: $white;
				border: 1px solid $p_border;
				margin-right: 3px;
				.switcher {
					background: $main_color_vivid;
					border-radius:100%;
					border:1px solid $white;
					width: 18px;
					height: 18px;
				}
			}
			td.p-objname a {
				&.save-filter.menu_edit {
					background: none;
					font-size: 11px;
					padding-left: 0;
					display: block;
				}
			}
		}
	}
	td.add-obr {
		background: $p_header;
		color: $main_font;
		font-size: 14px;
		line-height: 50px;
		padding-left: 28px;
		padding: 0;
		a:first-child {
			padding-left:15px;
		}
		.p-plus {
			background: $p_border;
			float: right;
			position: relative;
			width: 50px;
			height: 50px;
			cursor: pointer;
			a {
				padding-left: 0;
				img {
					position: absolute;
					padding: 15px;
				}
			}
		}
	}
}

#object-edit-portlet {
	.tab-form-light td {
	padding: 2px;
		&.label {
			line-height: 36px;
			text-align: right;
			padding-right: 10px;
			display: block;
			color: $mine_shaft;
			font-weight: normal;
			font-size: 12px;
		}
		&.input {
			input {
				margin: 0;
				height: 35px;
				width: 215px;
				border: 1px solid $color_iron_approx;
				padding: 10px;
			}
			select {
				height: 35px;
				width: 215px;
				border: 1px solid $color_iron_approx;
				padding: 0 6px;
				background: $url_34 no-repeat;
				background-size: 8px 5px;
				background-position: top 14px right 10px;
			}
		}
	}
}

// 2. LISTA SAL

#rooms-list-portlet { 

	.c-light {
		padding:0;
	}
	.panel-table {
		margin: 0;
	}
	
	.list-container {
		
		tr.on {
			background: $tr_hover;	
		}
		tr:hover {
		background: $tr_hover;
		cursor:pointer;
		}
		td {
			background: transparent;
		}
		td.p-rname {
		font-size: 15px;
		color: $table_font;
		}
	}
	td.add-obr {
		background: $p_header;
		color: $main_font;
		font-size: 14px;
		line-height: 50px;
		padding-left: 28px;
		padding: 0;
		a:first-child {
			padding-left:15px;
		}
		.p-plus {
			background: $p_border;
			float: right;
			position: relative;
			width: 50px;
			height: 50px;
			cursor: pointer;
			a {
				padding-left: 0;
				img {
					position: absolute;
					padding: 15px;
				}
			}

		}
	}
}

// EDYCJA SALI I OBIEKTU AJAX

#ajax-edit-portlet-portlet, #room-edit-portlet, #room-add-holder {
	td.label {
		line-height: 36px;
		text-align: right;
		padding-right: 10px;
		display: block;
		color: $mine_shaft;
		font-weight: normal;
		font-size: 12px;
		&.tooltip {
			display:block;
			position: static;
			opacity:1;
			text-align: right;
			font-family: $font_opensans, $font_backup;			
		}
	}
	.room_name {
		padding: 10px 0;
	}

	&.input {
		input {
			margin: 0;
			height: 35px;
			width: 215px;
			border: 1px solid $color_iron_approx;
			padding: 10px;
		}
		select {
			height: 35px;
			width: 215px;
			border: 1px solid $color_iron_approx;
			padding: 0 6px;
			background: $url_34 no-repeat;
			background-size: 8px 5px;
			background-position: top 14px right 10px;
		}
	}

	.reservation_color_picker_preview {
		width: 250px;
		height: 75px;
		margin-top: 44px;
		background:none;
		span {
			top:0!important;
			left:0!important;
			font-size:12px;
		}
		span#gantt-block-2 {
			display:none;
		}
	}
	td.tlabel {
		font-size: 12px;
		font-weight: normal;
		line-height: 36px;
		text-align: right;
		padding: 4px;
		display: block;
	}
	
	fieldset.tabs-container {
		border:1px solid $p_border;
		border-radius:0;
		margin:2px 10px;
		padding:0 0 10px 0;
	}

	input.submit {
		margin-left: 10px;
	}

	// katering

	#caterings-list {
		.status {
			.statuses_added {
				float:left;
				margin-right:10px;

				.header {
					padding: 0 10px;
					span.bold {
						font-weight:bold;
					}
					span.pointer {
						cursor:pointer;
					}
					span.showhide {
						float: right;
					}
				}
				.selected {
					li {
						height:50px;
						cursor:move;
						margin:3px 3px 3px 0;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	padding: 6px;
				    	position:relative;

						span.name {
							float:left;
							line-height:36px;
							font-weight:bold;
							margin-right: 10px;
						}
						.fields {
							float:right;
							input {
								margin-right: 30px;
								width: 80px;
							}
						}
						a.delete {
							position: absolute;
							top: 14px;
							right: 9px;
							background: $url_22 no-repeat;
							width: 20px;
							height: 20px;
						}
						&:hover {
							
						}
					}
				}
			}
			.statuses_available {
				border-left:1px solid $p_bg;
				float:left;
				padding-left: 13px;
				.header {
					padding: 0 15px;
					span.bold {
						font-weight:bold;
					}
					span.pointer {
						cursor:pointer;
					}
					span.showhide {
						float: right;
					}
				}
				.footer {
					a.addvalue {
						cursor:pointer;
						margin: 10px 0;
						padding: 10px 20px;
						float: left;
				    	background: $yes;
				    	color:$white;
					}
					.add_status_to_list {
						float:left;
						margin:10px 0;
						clear:both;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	width: 400px;
				    	position:relative;
				    	input {
				    		margin: 10px;
				    		float: left;
				    		width: 315px;
				    	}
				    	a.approve_inline {
				    		position:absolute;
				    		top:10px;
				    		right:10px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 0 8px 5px;
				    	}
				    	a.cancel_inline {
				    		position:absolute;
				    		top:10px;
				    		right:32px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 5px;
				    	}
					}
				}
				.selected {
					li {
						height:50px;
						cursor:move;
						margin:3px 3px 3px 0;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	padding: 6px;

						span.name {
							float:left;
							line-height:36px;
							font-weight:bold;
						}
						.fields {
							float:right;
						}
						a.delete {
							float:right;
						}
						&:hover {
							
						}
					}
				}
			}
		}
	}

	// uklad sali

	#disposals-list {
		.statuses_added {
			float:left;
			.header {
				padding: 0 10px;
				span.bold {
					font-weight:bold;
				}
				span.pointer {
					cursor:pointer;
				}
				span.showhide {
					float: right;
				}
			}
		}
		.statuses_available {
			float:left;
			.header {
				padding:0 10px;
				span.bold {
					font-weight:bold;
				}
			}
		}
		.sortable_elements {
				max-height:none;
				li {
					position: relative;
				    display: block;
				    border: 1px solid #000;
				    cursor: move;
				    color: #222222;
				    margin: 3px 3px 3px 0;
				    padding: 4px;
				    width: 300px;
				    height: 50px;
				    font-size: 11px;
				    text-align: left;
				    border: 1px solid $p_border;
				    background: $p_bg;
				    padding: 0 30px 0 20px;

				    a.delete {
						position: absolute;
						top: 14px;
						right: 9px;
						background: $url_22 no-repeat;
						width: 20px;
						height: 20px;
					}
					a.add {
						position: absolute;
						top: 14px;
						right: 9px;
						background: $url_47 no-repeat;
						width: 20px;
						height: 20px;
					}
					input {
						float: right;
						margin-right: 10px;
						width: 80px;
						margin-top: 6px;
					}
					span.name {
						float: left;
						font-size:13px;
						font-weight:bold;
						margin-top: 15px;
						margin-right: 10px;
					}
				}
			}
	}

	//akcesoria sali

	#accessories-list {
		.statuses_added {
			float:left;
			.header {
				padding: 0 10px;
				span.bold {
					font-weight:bold;
				}
				span.pointer {
					cursor:pointer;
				}
				span.showhide {
					float: right;
				}
			}
		}

		.statuses_available {
			float:left;
			.header {
				padding:0 10px;
				span.bold {
					font-weight:bold;
				}
			}
		}

		.sortable_elements {
				max-height:none;
				li {
					position: relative;
				    display: block;
				    border: 1px solid #000;
				    cursor: move;
				    color: #222222;
				    margin: 3px 3px 3px 0;
				    padding: 4px;
				    width: 300px;
				    height: 70px;
				    font-size: 11px;
				    text-align: left;
				    border: 1px solid $p_border;
				    background: $p_bg;
				    padding: 0 30px 0 20px;

				    a.delete {
						position: absolute;
						top: 22px;
						right: 9px;
						background: $url_22 no-repeat;
						width: 20px;
						height: 20px;
					}
					a.add {
						position: absolute;
						top: 22px;
						right: 9px;
						background: $url_47 no-repeat;
						width: 20px;
						height: 20px;
					}
					span.name {
						float: left;
						font-size:13px;
						font-weight:bold;
						margin-top: 6px;
						margin-right: 10px;
					}
					.fields {
						float:left;
						margin-top: 5px;
						border-top:1px solid $p_border;
						clear:both;
						label {
							margin: 5px;
							input {
							float: left;
							margin-right: 5px;
							}
						}
					}
				}
			}
	}

}

// EDYTUJ OBIEKT

#object-edit-holder {
	td.label {
		line-height: 36px;
		text-align: right;
		padding-right: 10px;
		display: block;
		color: $mine_shaft;
		font-weight: normal;
		font-size: 12px;
		&.tooltip {
			display:block;
			position: static;
			opacity:1;
			text-align: right;
			font-family: $font_opensans, $font_backup;			
		}
	}
	.room_name {
		padding: 10px 0;
	}

	&.input {
		input {
			margin: 0;
			height: 35px;
			width: 215px;
			border: 1px solid $color_iron_approx;
			padding: 10px;
		}
		select {
			height: 35px;
			width: 215px;
			border: 1px solid $color_iron_approx;
			padding: 0 6px;
			background: $url_34 no-repeat;
			background-size: 8px 5px;
			background-position: top 14px right 10px;
		}
	}

	.reservation_color_picker_preview {
		width: 250px;
		height: 75px;
		margin-top: 44px;
		background:none;
		span {
			top:0!important;
			left:0!important;
			font-size:12px;
		}
		span#gantt-block-2 {
			display:none;
		}
	}
	td.tlabel {
		font-size: 12px;
		font-weight: normal;
		line-height: 36px;
		text-align: right;
		padding: 4px;
		display: block;
	}
	
	fieldset.tabs-container {
		border:1px solid $p_border;
		border-radius:0;
		margin:2px 10px;
		padding:0 0 10px 0;
	}

	input.submit {
		margin-left: 10px;
	}

	// katering

	#caterings-list {
		.status {
			.statuses_added {
				float:left;
				margin-right:10px;

				.header {
					padding: 0 10px;
					span.bold {
						font-weight:bold;
					}
					span.pointer {
						cursor:pointer;
					}
					span.showhide {
						float: right;
					}
				}
				.selected {
					li {
						height:50px;
						cursor:move;
						margin:3px 3px 3px 0;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	padding: 6px;
				    	position:relative;

						span.name {
							float:left;
							line-height:36px;
							font-weight:bold;
							margin-right: 10px;
						}
						.fields {
							float:right;
							input {
								margin-right: 30px;
								width: 80px;
							}
						}
						a.delete {
							position: absolute;
							top: 14px;
							right: 9px;
							background: $url_22 no-repeat;
							width: 20px;
							height: 20px;
						}
						&:hover {
							
						}
					}
				}
			}
			.statuses_available {
				border-left:1px solid $p_bg;
				float:left;
				padding-left: 13px;
				.header {
					padding: 0 15px;
					span.bold {
						font-weight:bold;
					}
					span.pointer {
						cursor:pointer;
					}
					span.showhide {
						float: right;
					}
				}
				.footer {
					a.addvalue {
						cursor:pointer;
						margin: 10px 0;
						padding: 10px 20px;
						float: left;
				    	background: $yes;
				    	color:$white;
					}
					.add_status_to_list {
						float:left;
						margin:10px 0;
						clear:both;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	width: 400px;
				    	position:relative;
				    	input {
				    		margin: 10px;
				    		float: left;
				    		width: 315px;
				    	}
				    	a.approve_inline {
				    		position:absolute;
				    		top:10px;
				    		right:10px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 0 8px 5px;
				    	}
				    	a.cancel_inline {
				    		position:absolute;
				    		top:10px;
				    		right:32px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 5px;
				    	}
					}
				}
				.selected {
					li {
						height:50px;
						cursor:move;
						margin:3px 3px 3px 0;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	padding: 6px;

						span.name {
							float:left;
							line-height:36px;
							font-weight:bold;
						}
						.fields {
							float:right;
						}
						a.delete {
							float:right;
						}
						&:hover {
							
						}
					}
				}
			}
		}
	}

	// uklad sali

	#disposals-list {
		.statuses_added {
			float:left;
			.header {
				padding: 0 10px;
				span.bold {
					font-weight:bold;
				}
				span.pointer {
					cursor:pointer;
				}
				span.showhide {
					float: right;
				}
			}
		}
		.statuses_available {
			float:left;
			.header {
				padding:0 10px;
				span.bold {
					font-weight:bold;
				}
			}
		}
		.sortable_elements {
				max-height:none;
				li {
					position: relative;
				    display: block;
				    border: 1px solid #000;
				    cursor: move;
				    color: #222222;
				    margin: 3px 3px 3px 0;
				    padding: 4px;
				    width: 300px;
				    height: 50px;
				    font-size: 11px;
				    text-align: left;
				    border: 1px solid $p_border;
				    background: $p_bg;
				    padding: 0 30px 0 20px;

				    a.delete {
						position: absolute;
						top: 14px;
						right: 9px;
						background: $url_22 no-repeat;
						width: 20px;
						height: 20px;
					}
					a.add {
						position: absolute;
						top: 14px;
						right: 9px;
						background: $url_47 no-repeat;
						width: 20px;
						height: 20px;
					}
					input {
						float: right;
						margin-right: 10px;
						width: 80px;
						margin-top: 6px;
					}
					span.name {
						float: left;
						font-size:13px;
						font-weight:bold;
						margin-top: 15px;
						margin-right: 10px;
					}
				}
			}
	}

	//akcesoria sali

	#accessories-list {
		.statuses_added {
			float:left;
			margin-right: 10px;
			.header {
				padding: 0 10px;
				span.bold {
					font-weight:bold;
				}
				span.pointer {
					cursor:pointer;
				}
				span.showhide {
					float: right;
				}
			}
		}

		.statuses_available {
			border-left:1px solid $p_bg;
			float:left;
			padding-left: 13px;
			.header {
				padding:0 10px;
				span.bold {
					font-weight:bold;
				}
			}
			.footer {
					a.addvalue {
						cursor:pointer;
						margin: 10px 0;
						padding: 10px 20px;
						float: left;
				    	background: $yes;
				    	color:$white;
					}
					.add_status_to_list {
						float:left;
						margin:10px 0;
						clear:both;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	width: 400px;
				    	position:relative;
				    	input {
				    		margin: 10px;
				    		float: left;
				    		width: 315px;
				    	}
				    	a.approve_inline {
				    		position:absolute;
				    		top:10px;
				    		right:10px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 0 8px 5px;
				    	}
				    	a.cancel_inline {
				    		position:absolute;
				    		top:10px;
				    		right:32px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 5px;
				    	}
					}
				}
		}

		.sortable_elements {
				max-height:none;
				li {
					position: relative;
				    display: block;
				    border: 1px solid #000;
				    cursor: move;
				    color: #222222;
				    margin: 3px 3px 3px 0;
				    padding: 4px;
				    width: 400px;
				    height:80px;
				    font-size: 11px;
				    text-align: left;
				    border: 1px solid $p_border;
				    background: $p_bg;
				    padding: 0 30px 0 20px;

				    a.delete {
						position: absolute;
						top: 28px;
						right: 9px;
						background: $url_22 no-repeat;
						width: 20px;
						height: 20px;
					}
					a.add {
						position: absolute;
						top: 22px;
						right: 9px;
						background: $url_47 no-repeat;
						width: 20px;
						height: 20px;
					}
					span.name {
						float: left;
						font-size:13px;
						font-weight:bold;
						margin-top: 6px;
						margin-right: 10px;
						margin-bottom: 6px;
					}
					.fields {
						float:left;
						clear:both;
						border-top:1px solid $p_border;
						.field-container {
							margin: 5px 5px 5px 0;
							float:left;
							input {
							float: left;
							width:80px;
							}
						}
					}
				}
			}
	}

}

// DODAJ OBIEKT

#object-add-holder {
	td.label {
		line-height: 36px;
		text-align: right;
		padding-right: 10px;
		display: block;
		color: $mine_shaft;
		font-weight: normal;
		font-size: 12px;
		&.tooltip {
			display:block;
			position: static;
			opacity:1;
			text-align: right;
			font-family: $font_opensans, $font_backup;			
		}
	}
	.room_name {
		padding: 10px 0;
	}

	&.input {
		input {
			margin: 0;
			height: 35px;
			width: 215px;
			border: 1px solid $color_iron_approx;
			padding: 10px;
		}
		select {
			height: 35px;
			width: 215px;
			border: 1px solid $color_iron_approx;
			padding: 0 6px;
			background: $url_34 no-repeat;
			background-size: 8px 5px;
			background-position: top 14px right 10px;
		}
	}

	.reservation_color_picker_preview {
		width: 250px;
		height: 75px;
		margin-top: 44px;
		background:none;
		span {
			top:0!important;
			left:0!important;
			font-size:12px;
		}
		span#gantt-block-2 {
			display:none;
		}
	}
	td.tlabel {
		font-size: 12px;
		font-weight: normal;
		line-height: 36px;
		text-align: right;
		padding: 4px;
		display: block;
	}
	
	fieldset.tabs-container {
		border:1px solid $p_border;
		border-radius:0;
		margin:2px 10px;
		padding:0 0 10px 0;
	}

	input.submit {
		margin-left: 10px;
	}

	// uklad sali

	#disposals-list {
		.statuses_added {
			float:left;
			margin-right: 10px;
			.header {
				padding: 0 10px;
				span.bold {
					font-weight:bold;
				}
				span.pointer {
					cursor:pointer;
				}
				span.showhide {
					float: right;
				}
			}
		}
		.statuses_available {
			border-left:1px solid $p_bg;
			float:left;
			padding-left: 13px;
			.header {
				padding:0 10px;
				span.bold {
					font-weight:bold;
				}
			}
			.footer {
					a.addvalue {
						cursor:pointer;
						margin: 10px 0;
						padding: 10px 20px;
						float: left;
				    	background: $yes;
				    	color:$white;
					}
					.add_status_to_list {
						float:left;
						margin:10px 0;
						clear:both;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	width: 400px;
				    	position:relative;
				    	input {
				    		margin: 10px;
				    		float: left;
				    		width: 315px;
				    	}
				    	a.approve_inline {
				    		position:absolute;
				    		top:10px;
				    		right:10px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 0 8px 5px;
				    	}
				    	a.cancel_inline {
				    		position:absolute;
				    		top:10px;
				    		right:32px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 5px;
				    	}
					}
				}
		}
		.sortable_elements {
				max-height:none;
				li {
					position: relative;
				    display: block;
				    border: 1px solid #000;
				    cursor: move;
				    color: #222222;
				    margin: 3px 3px 3px 0;
				    padding: 4px;
				    width: 300px;
				    height: 50px;
				    font-size: 11px;
				    text-align: left;
				    border: 1px solid $p_border;
				    background: $p_bg;
				    padding: 0 30px 0 20px;

				    a.delete {
						position: absolute;
						top: 14px;
						right: 9px;
						background: $url_22 no-repeat;
						width: 20px;
						height: 20px;
					}
					a.add {
						position: absolute;
						top: 14px;
						right: 9px;
						background: $url_47 no-repeat;
						width: 20px;
						height: 20px;
					}
					input {
						float: right;
						margin-right: 10px;
						width: 80px;
						margin-top: 6px;
					}
					span.name {
						float: left;
						font-size:13px;
						font-weight:bold;
						margin-top: 15px;
						margin-right: 10px;
					}
				}
			}
	}

	// katering

	#caterings-list {
		.status {
			.statuses_added {
				float:left;
				margin-right:10px;

				.header {
					padding: 0 10px;
					span.bold {
						font-weight:bold;
					}
					span.pointer {
						cursor:pointer;
					}
					span.showhide {
						float: right;
					}
				}
				.selected {
					li {
						height:50px;
						cursor:move;
						margin:3px 3px 3px 0;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	padding: 6px;
				    	position:relative;

						span.name {
							float:left;
							line-height:36px;
							font-weight:bold;
							margin-right: 10px;
						}
						.fields {
							float:right;
							input {
								margin-right: 30px;
								width: 80px;
							}
						}
						a.delete {
							position: absolute;
							top: 14px;
							right: 9px;
							background: $url_22 no-repeat;
							width: 20px;
							height: 20px;
						}
						&:hover {
							
						}
					}
				}
			}
			.statuses_available {
				border-left:1px solid $p_bg;
				float:left;
				padding-left: 13px;
				.header {
					padding: 0 15px;
					span.bold {
						font-weight:bold;
					}
					span.pointer {
						cursor:pointer;
					}
					span.showhide {
						float: right;
					}
				}
				.footer {
					a.addvalue {
						cursor:pointer;
						margin: 10px 0;
						padding: 10px 20px;
						float: left;
				    	background: $yes;
				    	color:$white;
					}
					.add_status_to_list {
						float:left;
						margin:10px 0;
						clear:both;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	width: 400px;
				    	position:relative;
				    	input {
				    		margin: 10px;
				    		float: left;
				    		width: 315px;
				    	}
				    	a.approve_inline {
				    		position:absolute;
				    		top:10px;
				    		right:10px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 0 8px 5px;
				    	}
				    	a.cancel_inline {
				    		position:absolute;
				    		top:10px;
				    		right:32px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 5px;
				    	}
					}
				}
				.selected {
					li {
						height:50px;
						cursor:move;
						margin:3px 3px 3px 0;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	padding: 6px;

						span.name {
							float:left;
							line-height:36px;
							font-weight:bold;
						}
						.fields {
							float:right;
						}
						a.delete {
							float:right;
						}
						&:hover {
							
						}
					}
				}
			}
		}
	}

	//akcesoria sali

	#accessories-list {
		.statuses_added {
			float:left;
			margin-right: 10px;
			.header {
				padding: 0 10px;
				span.bold {
					font-weight:bold;
				}
				span.pointer {
					cursor:pointer;
				}
				span.showhide {
					float: right;
				}
			}
		}

		.statuses_available {
			border-left:1px solid $p_bg;
			float:left;
			padding-left: 13px;
			.header {
				padding:0 10px;
				span.bold {
					font-weight:bold;
				}
			}
			.footer {
					a.addvalue {
						cursor:pointer;
						margin: 10px 0;
						padding: 10px 20px;
						float: left;
				    	background: $yes;
				    	color:$white;
					}
					.add_status_to_list {
						float:left;
						margin:10px 0;
						clear:both;
						border: 1px solid $p_border;
				    	background: $p_bg;
				    	width: 400px;
				    	position:relative;
				    	input {
				    		margin: 10px;
				    		float: left;
				    		width: 315px;
				    	}
				    	a.approve_inline {
				    		position:absolute;
				    		top:10px;
				    		right:10px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 0 8px 5px;
				    	}
				    	a.cancel_inline {
				    		position:absolute;
				    		top:10px;
				    		right:32px;
				    		background: #000;
				    		cursor:pointer;
				    		width: 20px;
				    		height: 20px;
				    		margin: 8px 5px;
				    	}
					}
				}
		}

		.sortable_elements {
				max-height:none;
				li {
					position: relative;
				    display: block;
				    border: 1px solid #000;
				    cursor: move;
				    color: #222222;
				    margin: 3px 3px 3px 0;
				    padding: 4px;
				    width: 400px;
				    height:80px;
				    font-size: 11px;
				    text-align: left;
				    border: 1px solid $p_border;
				    background: $p_bg;
				    padding: 0 30px 0 20px;

				    a.delete {
						position: absolute;
						top: 28px;
						right: 9px;
						background: $url_22 no-repeat;
						width: 20px;
						height: 20px;
					}
					a.add {
						position: absolute;
						top: 22px;
						right: 9px;
						background: $url_47 no-repeat;
						width: 20px;
						height: 20px;
					}
					span.name {
						float: left;
						font-size:13px;
						font-weight:bold;
						margin-top: 6px;
						margin-right: 10px;
						margin-bottom: 6px;
					}
					.fields {
						float:left;
						clear:both;
						border-top:1px solid $p_border;
						.field-container {
							margin: 5px 5px 5px 0;
							float:left;
							input {
							float: left;
							width:80px;
							}
						}
					}
				}
			}
	}

}

// DODAJ ZDJECIE

#room-images-portlet {
	.c-light {
		padding: 0;
	}
	.dropzone {
		border:0;
		border-radius:0;
	}
}

// LISTA OBIEKTÓW

#objects-list-holder {
	table {
		tbody {
			tr.on {

			}
		}
	}
}

// FAKTURY

//dodaj fakture

#invoice-new-portlet {
	.portlet-content {
		overflow:auto;
	}
}

//edytuj fakture

#invoice-edit-portlet {
	.portlet-content {
		overflow:auto;
	}
}

.invoice_details-wrap, .order_details-wrap, .booking_details-wrap, .booking_parent-wrap {
	width: 1100px;
	overflow: hidden;
    position: relative;
	.actions {
		border-bottom: 1px solid $p_header;
		border-top: 1px solid $p_header;
		float: left;
		width: 100%;
		ul {
			background: $tr_hover;
			padding: 8px 0;
			li {
				display: inline;
				padding: 5px;
				a:hover {
					color: $main_color_vivid;
				}
			}
		}
	}
	.booking-info, .invoice_items {
		float: left;
		width: 750px;
		h4 {
			margin-left: 5px;
		}
		.booking_information {
			list-style-type: none;
			padding-left: 0;
			width: 100%;
			float: left;
			li.booking_informations, li.booking_comment {
				width: 50%;
				ul.information {
					width: 100%;
					li {
						width: 100%;
					}
				}
			}
			li.booking_accessories {
				width: 100%;
				border-top: 1px dashed $p_header;
				margin-top: 10px;
				div {
					width: 50%;
					float: left;
					ul.information {
						width: 100%;
						li {
							width: 100%;
						}
					}
				}	
			}
			li {
				float: left;
				list-style-type: none;
				/* &.column {
					float: left;
					list-style-type: none;
					border-right: 1px solid $p_header;
					&.empty {
						display: none;
					}
					&:last-child {
						border-right:0;
					}
				} */
				ul {
					padding-left: 15px;
					list-style-type: none;
					li {
						padding: 2px 0;
					}
				}
			}
			h4 {
				padding: 0 10px;
			}
			ul.information {
				list-style-type: none;
				li {
					text-align: left;
					font-size: 13px;
					.not_confirmed {
						color: $no;
					}
					.unpaid {
						color: $no;
						font-weight:bold;
					}
					.label {
						color: $second_font;
						padding: 0;
						font-size: 13px;
					}
					.confirmed {
						color: $yes;
					}
				}
			}
		}
		.booking-notice-list, .invoice-notice-list {
			width: 100%;
			float: left;
			margin-top: 10px;
			.ui-tabs {
				width: 725px;
				li {
					overflow: auto;
					.created_date, .note_content {
						clear: both;
					}
				}
			}
		}
	}
	.purchaser-info, .addresses {
		float: left;
		width: 350px;
		border-bottom: 1px solid $p_header;
		border-left: 1px solid $p_header;
		.purchaser, .invoice_informations {
			float: left;
			background: $p_bg;
			width: 100%;
			.purchaser-name, .invoice-name {
				float: left;
				width: 100%;
				border-bottom: 1px solid $p_header;
				padding: 0 0 5px 0;
				h4 {
					padding: 0 5px;
				}
				.data-show {
					height: 16px;
					overflow: hidden;
					cursor:pointer;
					background: $url_3 no-repeat;
					background-size: 6px 4px;
					background-position: top 5px right 20px;
					/* &:after {
						content: "\00a0";
						width:17px;
						height: 15px;
						background: $url_3 no-repeat;
						background-size: 6px 4px;
						border-radius:100%;
						display: inline-table;
						margin-left: 10px;
						background-position: center right;
					} */
					&.on {
						background: $url_32 no-repeat;
						background-size: 6px 4px;
						background-position: top 5px right 20px;
					}
				}
			}
			.purchaser-contact {
				float: left;
				width: 100%;
				padding: 0 0 5px 0;
				h4 {
					padding: 0 5px;
				}
			}
		}
		.add-note {
			float: left;
			background: $p_bg;
			padding-bottom: 20px;
			h4 {
				padding: 0 5px;
			}
			.module {
				.module_content {
					margin-left: 5px;
					margin-top: 0;
					textarea {
						width: 280px!important;
					}
				}
			}
			.submit {
				background: #39b54a;
    			color: #fff;
			    float: left;
			    font-size: 14px;
			    padding: 0 25px;
			    height: 36px;
			    text-align: center;
			    line-height: 36px;
			    margin-left: 5px;
			    cursor: pointer;
			}	
		}
	}

	.notice {
		width: 600px;
		display: inline-block;
		.notice-list {
			li {
				overflow: auto;
				.created_date {
					clear: both;
				}
				.note_content {
					float: none;
					clear: both;
				}
			}
		}
	}
}

#invoice-edit-holder, #invoice-new-portlet {
	.invoices {
		.tooltip {
			position:static;
			opacity:1;
			font-size:15px;
			font-family: $font_opensans, $font_backup;
			color:$main_font;
			&.cancel_inline:hover {
				cursor:pointer;
			}
			&.run-function-on-click {
				font-size:11px;
				cursor:pointer;
			}
			&.current_address_status {
				margin:5px 0;
			}
		}
		.panel-table .sort th.ordinal {
			width: 60px;
		}
		.panel-table .sort th.group {
			width: 125px;
		}
		.panel-table .sort th.num {
			width: 80px;
		}
		.panel-table .sort th.name {
			width: 200px;
		}
		.two-inputs {
			width:50%!important;
			&.in-first {
				margin-bottom: 0!important;
				padding-right: 3px;
			}
			&.in-last {
				input[type="text"] {
					width: 99%;
				}
			}
		}
		.form-select {
			margin-left: 0;
		}
		.invoice_number {
			.field_description {
				font-size: 11px;
			}
		}
		.buttons {
			&.clr {
				float: left;
				width: 100%;
			}
		}
		fieldset {
			&.row {
				float: left;
				display: block;
				width: 100%;
				margin: 10px 0;
				padding: 10px;
				.field-container {
					float:left;
					width: 100%;
					margin-bottom: 5px;
				}
				.field_description, .description {
					float: left;
					width: 100%;
				}
				label {
					display: block;
					font-size: 15px;
					color: $table_font;
					float: left;
				}
				input[type="text"] {
					width: calc(100% - 20px);
				}
				&:nth-child(1) {
					width: 25%;
				}
				&:nth-child(2) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(3) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(4) {
					width: 25%;
					border-left: 1px solid $p_header;
					padding-bottom: 30px;
				}
				&:nth-child(5) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(6) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(7) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(8) {
					width: 50%;
					border-left: 1px solid $p_header;
				}
				.description {
					font-size: 11px;
					padding: 0 1px;
				}
			}
			&.loaded_address {
				width:40%;
				float:left;
				margin-top:-50px;
				.loaded_address_wrap {
					clear:both;
				}
			}
			&.row_clear {
				float: left;
				display: block;
				width: 100%;
				margin: 10px 0;
			}
			&.invoice_listitem {
				padding: 0;
				table {
				}
				.thpos {
					position: relative;
				}
				.showtd {
					font-size: 9px;
					position: absolute;
					bottom: 1px;
					right: 10px;
					cursor: pointer;
					color: $main_color_vivid;
				}
				input.submit {
					background: $main_color_vivid;
					border: 0;
					width: 200px;
					height: 36px;
					color: $white;
					font-size: 14px;
				}
			}
		}
	}
	.invoices_corrective {
		.tooltip {
			position:static;
			opacity:1;
			font-size:15px;
			font-family: $font_opensans, $font_backup;
			color:$main_font;
			&.cancel_inline:hover {
				cursor:pointer;
			}
			&.run-function-on-click {
				font-size:11px;
				cursor:pointer;
			}
			&.current_address_status {
				margin:5px 0;
			}
		}
		.panel-table .sort th.ordinal {
			width: 60px;
		}
		.panel-table .sort th.group {
			width: 125px;
		}
		.panel-table .sort th.num {
			width: 80px;
		}
		.panel-table .sort th.name {
			width: 200px;
		}
		.two-inputs {
			width:50%!important;
			&.in-first {
				margin-bottom: 0!important;
				padding-right: 3px;
			}
			&.in-last {
				input[type="text"] {
					width: 99%;
				}
			}
		}
		.form-select {
			margin-left: 0;
		}
		.invoice_number {
			.field_description {
				font-size: 11px;
			}
		}
		.buttons {
			&.clr {
				float: left;
				width: 100%;
			}
		}
		fieldset {
			&.row {
				float: left;
				display: block;
				width: 100%;
				margin: 10px 0;
				padding: 10px;
				.field-container {
					float:left;
					width: 100%;
					margin-bottom: 5px;
				}
				.field_description, .description {
					float: left;
					width: 100%;
				}
				label {
					display: block;
					font-size: 15px;
					color: $table_font;
					float: left;
				}
				input[type="text"] {
					width: calc(100% - 20px);
				}
				&:nth-child(1) {
					width: 25%;
				}
				&:nth-child(2) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(3) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(4) {
					width: 25%;
					border-left: 1px solid $p_header;
					padding-bottom: 30px;
				}
				&:nth-child(5) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(6) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(7) {
					width: 25%;
					border-left: 1px solid $p_header;
				}
				&:nth-child(8) {
					width: 50%;
					border-left: 1px solid $p_header;
				}
				.description {
					font-size: 11px;
					padding: 0 1px;
				}
			}
			&.loaded_address {
				width:40%;
				float:left;
				.loaded_address_wrap {
					clear:both;
				}
			}
			&.row_clear {
				float: left;
				display: block;
				width: 100%;
				margin: 10px 0;
			}
			&.invoice_listitem {
				padding: 0;
				table {
				}
				.thpos {
					position: relative;
				}
				.showtd {
					font-size: 9px;
					position: absolute;
					bottom: 1px;
					right: 10px;
					cursor: pointer;
					color: $main_color_vivid;
				}
				input.submit {
					background: $main_color_vivid;
					border: 0;
					width: 200px;
					height: 36px;
					color: $white;
					font-size: 14px;
				}
			}
		}
	}
	.btn_invoices {
		float: left;
		font-size: 14px;
		padding: 0 20px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		margin-left: 10px;
		cursor: pointer;
	}
	.isave {
		background: $color_chateau_green_approx;
		color: $white;
		&:hover {
			background: $color_chateau_green_approx;
			color: $white;
		}
	}
	.iclose {
		background: $mine_shaft;
		color: $white;
		&:hover {
			background: $dp_header;
			color: $white;
		}
		a {
			color: $white;
			&:hover {
				color: $white;
				text-decoration: none;
			}
		}
	}
	.iopen {
		background: $mine_shaft;
		color: $white;
		&:hover {
			background: $dp_header;
			color: $white;
		}
		a {
			color: $white;
			&:hover {
				color: $white;
				text-decoration: none;
			}
		}
	}
	.ipdf {
		background: $mine_shaft;
		color: $white;
		&:hover {
			background: $dp_header;
			color: $white;
		}
	}
	.isendpdf {
		background: $mine_shaft;
		color: $white;
		&:hover {
			background: $dp_header;
			color: $white;
		}
	}
	.btn_notice {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		background: $main_color_vivid;
		font-size: 14px;
		color: $white;
		width: 200px;
		height: 36px;
		line-height: 36px;
		cursor: pointer;
	}
	.invoice_correct {
		font-weight: bold;
		font-size: 14px;
	}
}

// dodaj patność

.ui-dialog-content {
	padding:0!important;
	.dialog_invoice_payment {
		.field-container {
			margin-bottom: 10px;
		}
		label { 
			float:left;
			line-height:34px;
			width:100px;
			margin-right: 4px;
			text-align:right;

		}
		input {
			width:180px;
			float:left;
		}
		.field_description {
			margin-left:105px;
			clear:both;
		}
		span.ui-unit {
			line-height:34px;
			font-size:12px;
			letter-spacing:1px;
			margin-left:4px;
		}
	}
	footer {
		overflow:auto;
		margin-bottom:0!important;
		.submit_link {
			.submit {
				width:auto;
				float:left;
				height:36px;
				line-height:36px;
				cursor:pointer;
				padding:0 20px;
				color:$white;
				background: $yes;
				margin-left:10px;
				font-size:14px;
			}
		}
	}
}

// podgląd faktury

/* .invoice_preview {
	
	.invoice_view {
		list-style-type: none;
		padding-left: 0;
		width: 100%;
		float: left;
		li {
			&.column {
				float: left;
				list-style-type: none;
				border-left: 1px solid $p_header;
				padding: 5px;
				h4 {
					padding: 0 10px;
				}
				ul {
					padding-left: 15px;
				}
				&:first-child {
					border-left: none;
				}
			}
			&.invoice_items {
				width: 70%;
				tfoot.items_total {
					font-weight: bold;
					font-size: 14px;
				}
				.invoice_correct {
					font-weight: bold;
					font-size: 16px;
					text-align:center;
				}
				.invoice-payment-container {
					float: left;
					margin-bottom:20px;
					.invoice_payments {
						padding-left:0;
						li.header {
						display:block;
						float: left;
						width: 100%;
						background: $table_header;
						font-weight: bold;
							div {
								float: left;
								padding: 10px 10px 10px 5px;
							}
						&:hover {
								background: $table_header;
							}
						}
						li {
							width:100%;
							float: left;
							border-bottom:1px solid $p_border;
							div {
								float:left;
								width:20%;
								padding: 10px 10px 10px 5px;
							}
							&:hover {
								background: $tr_hover;
							}
						}
					}
					.invoice_payments_total {
						float: left;
						padding: 10px 10px 10px 5px;
						font-weight: bold;
						font-size: 14px;
					}
				}
			}
			&.addresses {
				width: 30%;
				li.column .address_content {
					line-height: 20px;
				}
			}
			&.invoice_informations {
				width: 30%;
				padding-top: 20px;
				float: right;
				margin-bottom: 10px;
				.information {
					list-style-type: none;
					li {
						text-align: left;
						line-height: 20px;
						.label {
							color: $second_font;
							padding: 0;
						}
						span {
							font-size: 12px;
						}
					}
				}
			}
		}
		ul.addresses li.column {
			width: 50%;
			padding: 10px;
			&:first-child {
				width: 50%;
				padding: 10px 10px 10px 0;
			}
			.header {
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
	.invoice_notices_list {
		float: left;
		width: calc(70% - 10px);
		border-radius: 0;
		border: 0;
		padding: 0;
		margin:10px 5px;
		ul {
			&.notices_tabs_header {
				border-radius: 0;
				padding: 0;
				background: $white;
				border: 1px solid $p_header;
				li {
					margin: 0;
					border-radius: 0;
					border: 0;
					background: $white;
					border-right: 1px solid $p_header;
					a.ui-tabs-anchor {
						padding: 16px;
						font-size: 13px;
						letter-spacing: 1px;
					}
					&.ui-state-active {
				background: $main_color_vivid;
				a.ui-tabs-anchor {
					color: $white;
				}
			}
				}
			}
			&.tabs_holder {
				border: 1px solid $p_header;
				border-top: 0;
				border-radius:0;
				li {
				list-style-type: none;
				border-bottom: 1px dashed $p_header;
				padding: 10px;
					.sender {
						font-weight: bold;
						letter-spacing: 1px;
					}
					.created_date {
						color: $p_border;
						font-size: 10px;
					}
					&:last-child {
						border-bottom:0;
					}
				}
			}
		}
	}
} */

// ikonki do rezerwacji
.booking-item {
	overflow: auto;
	clear: both;
	.booking-icons {
		float:right;
		.tooltip {
			display: inline-block!important;
		}
	}
}

// UŻYTKOWNICY

// edutuj

#user-edit-holder {
	input[type="text"], input[type="password"], select {
		width:200px;
		float: left;
	}

}

// dodaj

#user-add-holder {
	input[type=text] {
		width:200px;
	}
	input[type=password] {
		border-radius: 0;
		height: 36px;
		border: 1px solid $border;
		float: left;
		box-shadow:none;
		color: $b_font_color;
		width:200px;
		display: block;
		padding: 6px 12px;
		line-height:34px;
		background: $white;
		font-family: $font_opensans, $font_backup;
		margin:0;
	}
	select {
		width: 200px;
	}

}

// FIRMA

//edytuj firmę

.owner_row {
	margin-left: 20px;
	margin-bottom: 10px;
	.name {
		font-weight:bold;
		font-size:14px;
		margin-bottom: 5px;
	}
	select {
		width:275px;
	}
}


// ------------------------------ IKONY ------------------------------ //


// ------------------------------ SPAN -------------------------------- //

span {
	&.ui-unit {
		line-height:36px;
		font-size:12px;
		letter-spacing:1px;
		margin-left:5px;
	}
	&.count-control {
		height:36px!important;
		float: left;
		position: relative;
		width: 200px;
		a {
			position: absolute;
			/* display:none; */
			&.ui-spinner-up {
			    top:1px;
			    right: 1px;
			}
			&.ui-spinner-down {
			    bottom:6px;
			    right: 1px;
			}
		}
	}
}

// ------------------------------ INPUT -------------------------------- //

input.submit {
	background: $main_color_vivid;
	border: 0;
	width: auto;
	color: $white;
	font-size: 14px;
	margin-top: 10px;
	padding:10px 20px;
}


// ----------------------------- TABLE Default Form ------------------------ //

table.default_form {
	td.input {
		padding-bottom: 4px;
		a.removevalue {
			margin: 13px 0 7px 5px;
			width: 16px;
			height: 19px;
			cursor: pointer;
			float: left;
			background: $url_53 no-repeat 0 3px;
			&.block {
				display: inline-block;
			}
		}
		a.addvalue {
			background: $url_52 no-repeat 0 3px;
			cursor: pointer;
			padding:2px 0 2px 20px;
			display: inline-block;
			margin: 13px 5px;
		}
	}
}

ul.dropdown_inline {
	float: right;
	display:inline-block;
	li {
		position: relative;
		&:hover ul {
			display:block;
		}
		a {
		    display: block;
		    padding: 1px 5px;
		    font-size: 11px;
		    background: $table_header;
		    text-decoration: none;
		    &:hover {
		    	color: $white; 
		    	background: $menu_bg;
		    }
		}
	}
	li ul {
		display: none;
	    position: absolute;
	    left: 0;
	    width: 200px;
	    list-style: none;
	    z-index: 1;
	}
}


// ------------------------------ UI CUSTOM -------------------------------- //

.ui-widget {
	font-family: $font_opensans, $font_backup;
}

.ui-autocomplete {
	.ui-state-focus {
		background: $tr_hover;
	}
}

// ------------------------------- DATEPICKER TEMP --------------------------//

.calendar_navigator {
	.ui-datepicker {
		width: 100%;
		.ui-datepicker-header {
			background: $concrete;
			.ui-datepicker-prev {
				left: calc(50% - 125px);
				top: 18px;
				background: $url_25 no-repeat;
				background-position: center;
				cursor: pointer;
				span {
					background: none;
				}
			}
			.ui-datepicker-next {
				left: calc(50% + 100px);
				top: 18px;
				background: $url_26 no-repeat;
				background-position: center;
				cursor: pointer;
				span {
					background: none;
				}
			}
			.ui-datepicker-title {
				color: $header_top_font;
				font-size: 18px;
				font-weight: bold;
				width: 200px;
				margin-right: auto;
				margin-left: auto;
			}
		}
		table.ui-datepicker-calendar {
			margin: 0;
			float: right;
		}
	}
	tr {
		float: left;
		td {
			width: 28px;
			width: 29px;
		}
	}
	th {
		width: 28px;
		width: 29px;
	}
	.ui-state-disabled {
		background: $color_celeste_approx;
	}
}

// --------------------- OPINIONS ----------------------------//

#opinion-filter-holder .floatLeft {
	&:nth-child(1) label {
		width: 200px;
	}
	&:nth-child(2) label {
		width: 174px;
	}
	&:nth-child(3) label {
		width: 174px;
	}
}

table.list_opinion {
	tr {
		&.alert, &.resolve_alert {
			background: $color_travertine_approx;
			border-left: none;
			border-right: none;
		}
		&.request {
			background: $pastel_important_alert;	
		}
		.opinion_statuses {
			span {
				display: block;
				clear: both;
				margin-bottom:3px;
				margin-right: 10px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			img {
				padding: 0 3px 1px 0;
			}
		}
	}
	a.answear_link {
		line-height: 16px;
	}
	button {
		border-radius:3px;
		height: 25px;
		padding:0 8px;
		margin-right: 5px;
		font-size: 11px;
		background: $p_border;
	}
	.colapsed {
		a {
			float: left;
			clear: both;
		}
	}
}

.room-ratings-info {
	float: left;
	width:100%;
	padding: 0 8px 10px 8px;
}

.add-remark-container {
	.remark_remark {
		padding: 0 8px;
	}
}

// ikonki dodawania/usuwania maila

#user-edit-holder {
	.addvalue { 
		font-size: 12px;
		background:$url_13 no-repeat;	
	}
	.removevalue {
		background:$url_43 no-repeat;
	}
}

#time_logout {
	position: absolute;
    width: 210px;
    background: $menu_bg;
    top: 45px;
    right: 0;
    color:#fff;
    border-radius: 0 0 5px 5px;
    z-index: 1000000000;
    font-size: 11px;
    line-height: 30px;
    padding: 0 8px;
    display: none;
    div {
	    float: right;
	    display: inline-block;
	    font-size: 18px;

	}
	div > span {
	    float: right;
	    display: inline;
	    width: auto;
	}
}

// temp Strona logowania

.temp-info {
	padding: 20px;
	font-size:14px;
	text-align: center;
	border-top: 1px solid $p_border;
	font-weight: bold;
	line-height:26px;
	float: left;
	width: 100%;
	 h2 {
	 	font-weight: bold;
	 }
}

// Dialog Invoice preview

.ui-dialog.invoice_preview, .ui-dialog.booking_preview {
	.ui-dialog-content {
		overflow-x: auto;
	}
}

// dialog zmień dane do faktury

#data-invoice-preview {
	width:600px;
	fieldset {
		width: 100%;
		display: block;
		padding-bottom: 5px;
		.iua_label {
		    float: left;
		    padding: 0 8px;
		    width: 140px;
		    line-height: 36px;
		    text-align: right;
		}
		.iua_html {
			float: left;
			width: 450px;
		}
		.gus-client { 
			clear:both;
			float: left; 
			margin-left:150px; 
		}
	}
}

/* MODUŁ OPINII */

/* dodawanie opinii */
.edit-opinion-container {
	padding:10px;
	//width: 492px;
	border-right: 1px solid $border;
	span.title {
		font-size: 14px;
	}
	.field-container {
		overflow: auto;
		padding:10px 5px;
		border-bottom: 1px solid $table_header;
		.input {
			overflow: auto;
			width: 190px;
			float: left;
			position: relative;
			span {
				position: absolute;
				top: 4px;
				right: 0;
				font-size: 12px;
				font-weight: bold;
			}
			.rating {
				float: right;
				margin-right: 15px;
				padding-top: 2px;
				width: 105px !important;
    			height: 26px;
    			position: relative;
			}
		}
		.mylabel {
			font-size: 14px;
			font-weight: bold;
			width: 300px;
			float: left;
			line-height: 25px;
		}
		&.opinion_reason_all_rooms, &.opinion_reason_catering, &.opinion_reason_service, &.opinion_reason_object {
			padding-bottom: 4px;
			.input {
				width: 100%!important;
			}
		}
		&.opinion_remark {
			border-bottom: 0;
			.input {
				width: 100%;
			}
		}
		&:last-child {
			padding-top:0;
			border-bottom: 0;
		}
	}
	.field-container[class*="ro_weight"] {
		display: none!important;
		border-bottom: 0;
	}
	.field-container[class*="ro_reason_room"] {
		.input {
			width: 100%!important;	
		}
	}
	#not_single_rating {
		border-bottom: 1px solid $table_header;
		margin-bottom: 20px;	
	}
	#single_rating {
		margin-bottom: 20px;	
	}
	.opinion_all_rooms, .opinion_reason_all_rooms {
		border-bottom: 0;	
	}
	.opinion_is_single_rating {

	}
}

/* edycja oceny sali */
.room-ratings-info , .avarage-all-rooms {
	padding: 10px;
	float: left;
	h3 {
		margin-top: 0;
		font-size: 20px;
	}
}

.rating-avarage {
	float: left;
	line-height: 25px;
}

/* gwiazdki */
.rating { 
	width: 95px; 
	height: 26px; 
	float: left; 
	margin-right: 4px; 
	position: relative;
	.rating-bg { 
		position: absolute; 
		top:0; 
		left: 0; 
		width:95px; 
		height: 26px; 
		background: $url_26 no-repeat; background-size: 95px 26px; 
	}
	.rating-star { 
		position: absolute; 
		top: 0; 
		left: 0; 
		width:60%; 
		height: 26px; 
		background: $url_27 no-repeat; background-size: 95px 26px; 
	} 
}

#show_room_ratings-holder, #opinion-show_remarks-holder {
	a.submit {
		margin-right:5px;
		background: $main_color_vivid;
		border: 0;
		height: 36px;
		color: $white;
		font-size: 14px;
		margin-top: 10px;
		padding: 0 25px;
		line-height: 36px;
		margin: 0 0 5px 10px;
		display: inline-block;
		&:hover {
			background: $main_color_vivid_hover;
			-webkit-transition: background 0.2s linear; 
			-moz-transition: background 0.2s linear; 
			-ms-transition: background 0.2s linear; 
			-o-transition: background 0.2s linear; 
			transition: background 0.2s linear;
		}
	}
}

table.single-opinion {
	tr {
		td:nth-child(1) {
			width: 130px;
		}
		td:nth-child(2) {
			width: 50px;
			font-weight: bold;
			font-size: 14px;
		}
	}
	progress {
		height: 20px;
		width: 50%;
		-webkit-appearance: none;
		-moz-appearance: none;
   		appearance: none;
   		border: none;
	}
	progress::-webkit-progress-bar {
		height: 20px;
		background: $p_border;
	}
	progress::-moz-progress-bar {
		height: 20px;
		background: $main_color_vivid;
	}
	progress::-webkit-progress-value {
  		background: $main_color_vivid;
	}
}

.add-remark-container {
	padding: 2px 10px;
}

/* temp - przenieść do base */

a.back {
	background: $header_top_bg;
    border: 0;
    width: auto;
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    padding: 13.5px 20px;
    border-radius: 5px;
}

a.margin {
	margin-left:10px;
	display: inline-block;
}

// podgląd sali
.room_preview {
	.room-details {
		float: left;
		margin-bottom: 5px;
		width: 800px;
		h3 {
			font-size: 16px;
			font-weight: bold;
		}
		.basic {
			background: $p_bg;
			padding-bottom: 10px;
			border-bottom: 1px solid $border;
			.img {
				float: left;
	    		width: 150px;
	    		height: 90px;
	    		border: 1px solid $border;
			    margin-top: 10px;
			    margin-right: 20px;
			    background: $white;
				a {
					display: none;
				}
				a:first-child {
					display: block;
					img {
						object-fit: none; /* Do not scale the image */
	  					object-position: center; /* Center the image within the element */
	  					height: 90px;
	  					width: 150px;
					}
				}
			}
		}
		.room-disposals_wrap {
			float: left;
			width: 100%;
			margin-bottom: 35px;
			ul {
				padding-left: 0;
				li {
					list-style-type: none;
					display: inline;
					background: $white;
					border: 1px solid $border;
					width: 56px;
					height: 50px;
					float: left;
					margin-right: 5px;
					font-size: 10px;
					img {
						margin-bottom: 5px;
					}
				}
			}
		}
		.room-equipment_wrap, .room-catering_wrap {
			table {
				width:100%;
				tr {
					border-bottom: 1px solid $border;
					td.col-name {
						width: 80%;
						padding: 5px;
					}
					td.col-price {
						width: 20%;
						padding: 5px;
					}
				}
			}
		} 
	}	
}

// backup widget

.backup_wrapper {
	display: inline;
	position: relative;
	margin-left: 5px;
	a.backup {
		cursor: pointer;
		color: $white;
		background: $main_color_vivid;
		padding: 4px 10px;
		border-radius: 4px;
		img {
			margin-right: 8px;
		}
	}
	.backup_list {
		position: absolute;
		background: $white;
		border: 1px solid $p_border;
		top: 20px;
		left:0;
		padding:5px 0 5px 5px;
		width: 411px;
		z-index: 2;
		h4 {
			background: #eaeaea;
		    margin: 0;
		    padding: 10px;
		    margin-right: 5px;
		    letter-spacing: 0;
		    font-size: 17px;
		}
		.col {
			float: left;
			&.first {
				border-right: 1px solid $p_border;
				margin-right: 5px;
			}
			ul {
				width: 60px;
			}
			.submit {
				height: 30px;
				padding: 0 25px;
				margin-right: 5px;
			}
			input[name="mail"] {
				margin-top: 36px;
				display: block;
			}	
		}
	}
	&.bottom {
		.backup_list {
			position: absolute;
			background: $white;
			border: 1px solid $p_border;
			bottom: 20px;
			left:0;
			padding:5px 0 5px 5px;
			width: 411px;
			z-index: 2;
			top: auto;
		}	
	}	
}

#resend-token-portlet {
	.portlet-header {
		.minmax { 
			display: none;
		}
	}
}

.room_review_dialog, .rules_dialog, .faq_dialog, .share_dialog, .contact_dialog {
	&.ui-dialog {
		.ui-dialog-content {
			//max-height: 600px!important;
			overflow-y: auto;
		}
		/* .ui-dialog-titlebar-close {
			right: 10px;
			background: none!important;
			span {
				display: block!important;
			}
			.ui-icon {
				background: $url_102 no-repeat;
				background-position: 40% 40%;
			}
			&.ui-state-focus, &.ui-state-active {
				.ui-icon {
					background: $url_102 no-repeat;
					background-position: 40% 40%;
				}
			}
		} */
	}
	.room_info {
		padding:0 20px;
		float: left;
		width: 100%;
		padding-bottom: 15px;
		background: $color_aqua_haze_approx;
		h3 {
			font-weight: bold;
			font-size: 20px;
			margin-bottom: 5px;
		}
		span {
			line-height: 26px;
			float: left;
			&.number {
				margin-left: 5px;
				font-size: 10px;
			}
		}
		.rating {
			margin-left: 5px;
		}
	}
	ul.room_opinions {
		margin: 0 15px 15px 15px;
		float: left;
		li {
			div:nth-child(1) {
				width: 200px;
				float: left;
				border-right: 1px dashed #ccc;
			}
			div:nth-child(2) {
				float: left;
				width: 590px;
				padding-left: 10px;
				.rating-parts td {
					padding: 0px 8px;
				    margin-right: 7px;
				    font-size: 11px;
				    font-weight: bold;
				}
				span.rating_desc {
					width: 100%;
					float: left;
					margin-left: 10px;
					font-weight: normal;
					font-size:11px;
					line-height: 18px;
					padding: 5px 0;
					border-bottom: 1px solid #eaeaea;
					span {
						font-weight: normal;
						padding: 5px 10px;
						margin:5px 0;
						border-radius:4px;
						&:nth-child(odd) {
							margin-left:20px;
							background:$early_dawn;
						}
						&:nth-child(even) {
							background:$color_aqua_haze_approx;
						}
					}
					&:last-child {
						border-bottom:0;
					}
				}
			}
			padding: 15px 15px;
			border-bottom: 1px solid #eaeaea;
			float: left;	
			span {
				display: block;
				font-weight: bold;
				&.date {
					overflow: hidden;
					height: 14px;
					font-size: 11px;
					font-weight: normal;
					width:150px;
					color:#b9b9b9;
				}
				&.rate {
					font-size: 13px;
					float: left;
					text-transform: uppercase;
					span {
						color: $color_glacier_approx;	
						font-size: 15px;
						display: inline-block;
					}
				}
				&.nickmail {
					float: left;
					clear: both;
					margin-top: 10px;
				}
				&.rate-number {
					float: left;
					margin-top: 4px;
				}
			}
			&:last-child {
				border-bottom: 0;	
			}
			i {
				color: $color_nobel_approx;
			}
		}
	}
	.rules_wrapper {
		padding: 20px;
	}
	.faq_wrapper {
		padding: 20px;
		float: left;
		width: 100%;
		.help-col {
			text-align: center;
			margin-top: 20px;
			font-size: 12px;
			ol {
				text-align: left;
			}
			h5 {
				margin-bottom: 15px;
			}
		}
		.help-link {
			float: left;
			width: 100%;
			text-align: center;
			background: $color_vermilion_approx;
			color: $white;
			padding: 15px 0 12px 0;
		}
		ul {
			float: left;
			width: 100%;
			margin-top: 20px;
			margin-bottom: 10px;
			li {
				margin-bottom: 5px;
				h3 {
					cursor: pointer;
					margin: 0;
					font-size: 13px;
					font-weight: bold;
					border: 1px solid $color_mercury_approx;
					background: $color_gallery_approx;
					padding:8px 10px;
				}
				.answear {
					display: none;
					padding:15px;
				}
				&:last-child {
					margin-bottom:0;
				}
			}
		}
		a {
			color: $color_vermilion_approx;
			&.link {
				margin-bottom:3px;
				float: left;
				width: 100%;
				color: $white;
				font-size: 14px;
			}
		}
	}
	.contact_wrapper {
		padding: 20px;
		float: left;
		width: 100%;
		.contact-col {
			&:first-child {
				border-left: 0;
			}
			text-align: center;
			margin-top: 30px;
			font-size: 12px;
			margin-bottom: 15px;
			ol {
				text-align: left;
			}
			h5 {
				margin-bottom: 15px;
			}
		}
		.account-number {
			background: $color_alabaster_approx;
			float: left;
			width: 100%;
			padding: 8px 16px;
			margin:20px 0;
			font-size: 14px;
			padding-bottom: 16px;
		}

		.contact-link {
			float: left;
			width: 100%;
			text-align: center;
			background: $color_vermilion_approx;
			color: $white;
			padding: 15px 0 12px 0;
		}
		h4 {
			font-size: 16px;
		}
		a {
			color: $color_vermilion_approx;
			&.link {
				margin-bottom:3px;
				float: left;
				width: 100%;
				color: $white;
				font-size: 14px;
			}
		}
	}
	.share_wrapper {
		padding: 20px;
		table {
			width: 100%;
			tr {
				height: 50px;
				border-bottom: 1px solid $color_mercury_approx;
				line-height: 50px;
				&:last-child {
					border:0;
				}
				td {
					padding:5px 10px;
					&:first-child {
						background: $color_gallery_approx;
						text-align: center;
					}
				}
			}
		}
		button.copy {
			color: $white;
			background: $color_vermilion_approx;
			font-size: 14px;
		}
	}
}

// przycisk submit zawsze na wierzchu

.footer-info-container {
    opacity: 0; 
    height: 0;
	float: left;
	width: 100%;
	z-index: 4;
	position: relative;
	
	&.active {
		-webkit-transition: all .3s ease .15s;
		-moz-transition: all .3s ease .15s;
		-o-transition: all .3s ease .15s;
		-ms-transition: all .3s ease .15s;
		transition: all .3s ease .15s;
		opacity: 1;
    	height: 40px;
    	z-index: 4;
    	position: relative;
    	.info-wrapper {
			background: #d2cfcf;
			border-top: 1px solid #d2cfcf;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 40px;
			z-index: 4;
			line-height: 40px;
			font-weight: bold;
			text-align: center;
			font-size: 13px;
		}
	}
}

// powiadomienia sms

table#powiadomienia {
	td.label {
		display: none;
	}
}

.phone-settings {
	float: left;
	clear: both;
	margin: 5px 0;
}

span.phone-status {
	line-height:30px;
	padding: 3px 15px;
	&.phone-confirmed {
		color: #5A801B;
		background-color: #dff0d8;
		border: 1px solid #d6e9c6;
	}
	&.phone-unconfirmed {
		border: 1px solid #ebccd1;
      	background: #f2dede;
      	color: #CC0000;
      	cursor: pointer;
	}
	&.phone-inprocess {
		color: #4985B2;
		background: #d9edf7;
		border: 1px solid #bce8f1;
	}
}

a.confirm-phone {
	display: none;
}

span.sms-notification-disabled {
	border: 1px solid #ebccd1;
    background: #f2dede;
    color: #CC0000;
    float: left;
    clear: both;
    padding: 5px;
}

/* voucher link */
a.voucher_client_link {
	color: white;
	padding: 4px 10px 4px 25px;
    border-radius: 4px;
    margin-left: 10px;
    background: $url_61 $header_top_font no-repeat;
   	background-size: 11px 16px;
   	background-position: top 5px left 6px;
   	background-position-x: 6px;
   	background-position-y: 5px;
}