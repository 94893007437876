@media only screen and(max-width : 1199px) {
	.menugrey-container {
		nav {
			ul.p-useroptions {
				li.username {
					display: none;
					span.your-name {
						max-width: 300px;
						overflow: hidden;
    					text-overflow: ellipsis;
    					white-space: nowrap;
						display: block;
					}
				}
				li.username-min {
					display: inline-block;
					line-height: 45px;
				}
				li {
					a {
						padding: 14px 8px;
					}
				}
				.menu-only-rwd {
					display: none;
				}
			}
		}
	}

	#info {
		position:absolute;
		width:100%;
		margin-right: auto;
	    margin-left: auto;
	    padding-left: 0;
	    padding-right: 0;
		z-index: 100;
		left: 0;
		&.i-nav {
			position:fixed;
			top:45px;
			width:100%;
			z-index: 100;
			left: 0;
		}
		.hint-title {
			font-size: 16px;
			padding-bottom: 10px;
		}
		.hint-booking-changes {
			ul > li {
				font-weight:normal;
			}
		}
	}
}

@media only screen and(max-width : 991px) {
	// MENU RWD
	.navbar-toggle {
		border: 1px solid $white;
		margin-top: 24px;
		.icon-bar {
			background: $white;
		}
	}

	.smaller {
		.navbar-toggle {
			margin-top: 14px;
		}
	}

	.menu-rwd {
		background: $color_alabaster_approx;
		width: 100%;
		ul.menu {
			width: 100%;
			margin:0;
			li {
				width: 100%;
				clear: both;
				display: block;
				text-align: center;
				border-bottom: 1px solid $color_gallery_approx;
				&.settings {
					display: none;
				}
				.username {
					color: $black;
					padding: 10px;
					display: block;
					background: $color_gallery_approx;
					font-size: 16px;
				}
				a {
					color: $mine_shaft;
					height: 50px;
					line-height: 50px;
					font-size: 12px;
					font-weight: 400;
				}
			}
		}
	}

	.rwd-only {
		display: block!important;
	    max-height: 340px;
	}

	#time_logout {
		position: static;
	}

	.menugrey-container {
		nav {
			ul.p-useroptions {
				li.username {
					display: none;
				}
			}
		}
	}

	table {
		display: block;
		overflow: auto;
	}

	footer {
		position: static!important;
	}

	body {
		padding-bottom: 0!important;
	}
}

@media only screen and(max-width : 768px) {

	.menu-fix {
		.panel-top-header {
			.top-contact {
				display: none;
			}	
		}
		.navbar-toggle {
			display: block;
			border: 1px solid $color_celeste_approx;
			position: absolute;
			top: 26px;
			right: 15px;
			margin-top: 0;
			.icon-bar {
				background: $color_celeste_approx;
			}
		}
	}

	/* NAV */

	.menu-fix {
		border-bottom:1px solid $p_border;
	}

	.menugrey-container { 
		&.menu-toggle {
			display: none !important;
			&.on {
				display: block !important;
				.container {
					padding-left: 0;
					padding-right: 0;
					width: 100%;
				}
			}
		}
		nav {
			height: 60px;
			&.p-menu {
				height: auto;
				overflow: auto;
				max-height: 100%;
				ul {
					display: block;
					width: 100%;
					li {
						display: block;
						width: 100%;
						text-align: center;
						a {
							display: block;
							padding: 0;
							margin: 0;
							&.current {
								display: block;
								padding: 0;
								margin: 0;
							}
							&:hover {
								display: block;
								padding: 0;
								margin: 0;
							}
						}
					}
				}
				ul.p-useroptions {
					text-align: center;
					background: $black;
					width: 100%;
					//padding: 0 15px;
					height: auto;
					li {
						display: inline-block;
						width: auto;
						float: left;
						&.settings {
							display: none;
						}
						&.logout a {
							padding: 0 15px;
						}
						&.username {
							display: block;
							padding-left: 15px;
						}
						&.username-min {
							display: none;
						}
						&.root_link {
							display: none;
						}
						&.cart {
							a {
								padding: 4px 8px 14px 14px;
							}
						}
					}
					.menu-only-rwd {
						display: block;
						li {
							display: block;
							width: 100%;
							clear: both;
							a {
								color: $white;
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}
	.user-login-static {
		padding-top:20px;
		h4 {
			line-height: 24px;
		}
		.login, .registration {
			width: 100%;
			border: none;
		}
		.login {
			border-bottom: 1px solid $p_border;
			margin-bottom: 10px;
			padding-bottom: 20px;
		}
	}
}

@media only screen and(max-width : 680px) {

	.panel-top-header {
		.top-tittle {
			font-size: 24px;
			.name {
				line-height: 24px;
			}
		}
		.top-tittle2 {
			clear: both;
			border-left:0;
			margin-left: 0;
			font-size: 10px;
			padding:2px;
			margin-top: 12px;
			a {
				display: block;
				clear: both;
				img.logo {
					width: 130px;
					top:15px!important;
					margin: 0;
					left: 0;
				}
			}
		}
		.top-contact {
			float: none;
			font-size: 11px;
			padding: 0;
			img {
				display: none;
			}
		}
	}

	//rejestracja
	.registration-form {
		input.submit {
			width: 100%;
			padding: 10px;
		}
	}

	.portlet-header {
		height: auto;
		overflow: auto;
		.list_content {
			max-width: 80%;
			line-height: initial;
		    float: left;
		    text-align: left;
		    font-size: 14px;
		    padding-top: 18px;
		    padding-bottom: 18px;
		}
	}

	.ui-dialog {
	    width: 100% !important;
	    left: 0 !important;
	    margin-left: 0px;
	}

}

@media only screen and(max-width : 479px) {

	.portlet-header {
		height: auto;
		overflow: auto;
		.list_content {
			max-width: 80%;
			line-height: initial;
		    float: left;
		    text-align: left;
		    font-size: 14px;
		    padding-top: 18px;
		    padding-bottom: 18px;
		}
	}

	.user-login-static {
		.social-login {
			div {
				a.google-login {
					clear: both;
					margin-top: 10px;
				}
			}
		}
	}

	// przypomnienie hasła
	h5 {
		line-height: 22px;
	}
	.user_email {
		.tlabel, .input {
			display: block;
			clear: both;
			text-align: center;
			width: 100%;
		}
	}
	.forgot-pass, #resend-token-holder {
		table.taccount {
			input[name="user_email"] {
				width: 200px!important;
			}
		}
	}

	.static_filter_icons {
		display: block;
	    clear: both;
	    float: left;
	    width: 100%;
	    padding-bottom: 10px;
	}
}