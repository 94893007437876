// MAIN

html {
	height: 100%;
  	box-sizing: border-box;
}

html, body {
	margin: 0;
	font-family: $font_opensans, $font_backup;
	font-size: 12px;
	color: $mine_shaft;
}

body {
  	position: relative;
  	margin: 0;
  	padding-bottom: 208px;
  	min-height: 100%;
}

a {
	color: $mine_shaft;
	text-decoration:none;
	cursor: pointer;
	&.no-action {
		pointer-events: none;
   		cursor: default;
	}
	&:hover {
		text-decoration:none;
		outline: none;
		color: $mine_shaft;
	}
	&:focus {
		text-decoration:none;
		outline: none;
	}
}

button:focus {
	box-shadow: none;
  	outline: 0 none;
}

a:focus, span:focus  {
  	outline: 0 none;
}

/* do forms = temp */

#page_content {
	margin: 0 auto;
    width: 100%;
    overflow: auto;
}
.relative {
	position: relative;
}
/* #content {
	margin-top: 86px;
} */
.content {
	min-height: 100%;
}
.plist {
	margin-top: 96px;
	//position: static!important;
}
.floatLeft {
	float: left;
}
.clear {
	clear: both;
}
ul {
	list-style-type:none;
	padding: 0;
	margin: 0;
}
label { 
	font-weight:normal;
}

.tooltip {
	z-index: 1;
	position: fixed;
	display: block;
	position: static;
	opacity: 1;
	text-align: right;
	position: relative;
	&:after {
		content: "\00a0";
		text-indent: -999px;
		position: absolute;
		width: 14px;
		height: 14px;
		right: 10px;
		top: 50%;
		margin-top: -8px;
		//background: $url_12 no-repeat center; //_url
		background-size: 14px 14px;
	}
	&.no-icon {
		&:after {
			content: "";
			background: none;
		}
	}
}

.ui-tooltip-content {
	.more {
		font-size: 11px;
		clear: both;
		display: block;
		margin-top: 8px;
    	border-top: 1px solid $table_header;
    	padding-top: 6px;
	}  
}

.tooltip {
    z-index: 1 !important;
    position: static !important;
}

.menu-settings {
	border-radius:4px!important;
	.username {
		font-size: 13px;
	    color: #323232;
	    padding: 10px;
	    display: block;
	    width: 100%;
	    font-weight: 600;
	    //text-align: center;
	}
	hr {
		margin:0;
	}
	ul {
		list-style-type: none;
		padding:5px;
		li {
			padding: 0!important;
			a {
				height: 20px!important;
				padding: 0 5px;
				font-size: 12px;
				font-weight: normal;
				color: $mine_shaft!important;
				line-height: 20px!important;
			}
		}
	}
}

.ph10 {
	padding: 20px 0;
}

/* // SCROLLBAR

::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}
::-webkit-scrollbar-track {
	background-color: $p_header;
	border-left: 1px solid $color_celeste_approx;
}
::-webkit-scrollbar-thumb {
	background-color: $color_celeste_approx;
	&:hover {
		background-color: $color_silver_chalice_approx;
	}
} */

//PORTLETS

.portlet {
	border: 1px solid $p_border!important;
	margin-bottom: 10px;
	border-radius: 0;
}
.portlet-box {
	padding-left: 0;
	padding-right: 0;
}
.portlet-content {
	font-family: $font_opensans, $font_backup;
	background: $p_bg;
	padding-bottom: 8px;
	label {
		font-size: 12px;
		font-weight: normal;
		margin-right: 5px;
	}
}
.portlet-header {
	font-size: 18px;
	color: $white;
	background: $p_header_new;
	border-radius: 0;
	padding: 0 20px 0 0;
	font-weight: normal;
	border: none;
	letter-spacing: 1px;
	font-family: $font_opensans, $font_backup;
	height: 54px;
	border-bottom: 1px solid $p_border;
	.ui-icon {
		float: right;
		width: 16px;
		height: 16px;
		display: block;
	}
	.ui-icon-triangle-1-n {
		float: right;
		background: $url_32 no-repeat;
		background-position: center;
		background-size: 8px 5px;
		cursor: pointer;
		margin-top: 18px;
	}
	.ui-icon-triangle-1-s {
		float: right;
		background: $url_34 no-repeat;
		background-position: center;
		background-size: 8px 5px;
		cursor: pointer;
		margin-top: 18px;
	}
	.list_content {
		color: $main_font;
		line-height: 54px;
		float: left;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0px;
		text-align: center;
		margin-left: 20px;
		margin-right: 10px;
	}
}

// SUBMENU 
	#tabs {
		border-bottom:1px solid $p_border;
	}


// PORTLET FILTER do portleta

.portlet {
	.filter_content {
		padding: 8px 20px;
		overflow: auto;
	}
}

// TABLE DEFAULT

.default-table {
	width: 100%;
	font-size: 12px;
	margin-bottom: 20px;
	td {
		padding: 7px 5px;
		color: $table_font;
		a {
			line-height: 22px;
			&.red {
				text-decoration: line-through;
				color: $no;
			}
		}
		.input-wrapper {
			margin-top: 2px!important;
		}
	}
	tr {
		border-bottom:1px solid $p_border;
		&.p-light2 {
			width: 100%;
			background: $table_header;
			&:hover {
				background: $table_header;
			}
			th.sort.inactive span {
				background: $url_49 no-repeat right center;
				cursor:pointer;
				padding-right: 19px;
			}
			th.sort.desc span {
				background: $url_50 no-repeat right center;
				cursor:pointer;
				padding-right: 19px;
			}
			th.sort.asc span {
				background: $url_51 no-repeat right center;
				cursor:pointer;
				padding-right: 19px;
			}
		}
	}
	tr:hover {
		background:$tr_hover;
	}
	.sort {
		.inactive {
			background: none;
		}
		th {
			font-size: 14px;
			color: $table_font;
			padding: 10px 10px 10px 5px;
		}
	}
}

.folden_toggle {
	cursor: pointer;
	&:after {
		content: "\00a0";
		width:17px;
		height: 15px;
		background: $url_34 no-repeat;
		background-size: 6px 4px;
		border-radius:100%;
		display: inline-table;
		margin-left: 10px;
		background-position: center right;
	}
	&.expanded {
		&:after {
			content: "\00a0";
			width:17px;
			height: 15px;
			background: $url_32 no-repeat;
			background-size: 6px 4px;
			border-radius:100%;
			display: inline-table;
			margin-left: 10px;
			background-position: center right;
		}
	}
}



// TABS
form {
	.ui-tabs {
		margin:10px;
		border:1px solid $p_border;
		border-radius:0;
		padding:0;
		.ui-tabs-nav {
			background: $white;
			border-radius: 0;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border:0;
			border-bottom: 1px solid $p_border;
			padding: 0;
			margin: 0;
			li {
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
				background: $white;
				border: 0;
				margin: 0;
				border-right: 1px solid $p_border;
				a.ui-tabs-anchor {
					padding: 16px;
					font-size: 13px;
					letter-spacing: 1px;
					color: $main_font;
				}
				&.ui-state-active {
					background: $main_color_vivid;
					a.ui-tabs-anchor {
						color: $white;
					}
				}
				&.ui-tabs-active {
					padding-bottom: 0;
				}
				&.tab-title-light a.ui-tabs-anchor {
					padding: 16px;
					font-size: 13px;
				}
			}

			.tab-title-light {
				margin: 0;
				border-right: 1px solid $p_border;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
			}
		}
		.tab-header-light {
			padding: 0;
		}
		.tab-content-light {
			background: $white;
		}
		input.submit {
			margin:10px;
			margin-top:0;
		}
		table.tab-container {
			tr {
				margin-bottom: 4px;
				td {
					padding-bottom: 4px;
					&.label {
						padding:0 10px 0 0;
						font-size: 100%;
						opacity:1;
						text-align: right;
						color: $main_font;
						font-weight: normal;
						font-size: 12px;
						line-height: 36px;
					}
					&.information {
						font-size: 12px;
						padding: 0;
						padding-left:10px;
						line-height: 36px;
					}
				}
			}
		}
	}
	&.form-changed {
		.submit {
			background: red;
			-webkit-transition: background .4s linear;
  			-moz-transition: background .4s linear;
  			&.back {
  				background: $header_top_line;
  			}
		}
		input[type="submit"] {
			background: red;
			-webkit-transition: background .4s linear;
  			-moz-transition: background .4s linear;
		}
	}
}

.ui-tabs {
	margin:10px;
	border:1px solid $p_border;
	border-radius:0;
	padding:0;
	.ui-tabs-nav {
		background: $white;
		border-radius: 0;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border:0;
		border-bottom: 1px solid $p_border;
		padding: 0;
		margin: 0;
		li {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			background: $white;
			border: 0;
			margin: 0;
			border-right: 1px solid $p_border;
			a.ui-tabs-anchor {
				padding: 16px;
				font-size: 13px;
				letter-spacing: 1px;
				color: $main_font;
			}
			&.ui-state-active {
				background: $main_color_vivid;
				a.ui-tabs-anchor {
					color: $white;
				}
			}
			&.ui-tabs-active {
				padding-bottom: 0;
			}
			&.tab-title-light a.ui-tabs-anchor {
				padding: 16px;
				font-size: 13px;
			}
		}

		.tab-title-light {
			margin: 0;
			border-right: 1px solid $p_border;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
		}
	}
	.ui-tabs-panel {
		li {
			border-bottom: 1px dashed #d7d7d7;
    		padding: 10px;
    		.sender {
			  	font-weight: bold;
			  	letter-spacing: 1px;
			  	position: relative;
			  	a.removevalue {
					position: absolute;
					right: 0;
					top: 0;
					width: 20px;
					height: 20px;
					background: $url_22 no-repeat 0 3px;
					cursor: pointer;
			  	}
			}
			.created_date {
			    color: #e3e3e3;
			    font-size: 10px;
			}
		}
	}
	.tab-header-light {
		padding: 0;
	}
	.tab-content-light {
		background: $white;
	}
	input.submit { 
		margin:10px 5px 10px 10px;
	}
	span.next-tab {
		display: inline-block;
		margin: 10px 5px 10px 10px;
		padding: 9px 20px;
		background: $main_color_vivid;
		color: $white;
		font-size: 14px;
		cursor:pointer;
		&:hover {
			background: $main_color_vivid_hover;
			-webkit-transition: background 0.2s linear; 
			-moz-transition: background 0.2s linear; 
			-ms-transition: background 0.2s linear; 
			-o-transition: background 0.2s linear; 
			transition: background 0.2s linear;
		}
	}
	table.tab-container {
		tr {
			margin-bottom: 4px;
			td {
				padding-bottom: 4px;
				&.label {
					padding:0 10px 0 0;
					font-size: 100%;
					opacity:1;
					text-align: right;
					color: $main_font;
					font-weight: normal;
					font-size: 12px;
					line-height: 36px;
				}
				&.information {
					font-size: 12px;
					padding: 0;
					padding-left:10px;
					line-height: 36px;
				}
			}
		}
	}
}


// ELEMENTY SORTOWANE
.lists-container {
	.statuses_added, .statuses_available {
		float: left;
		.header {
			.bold {
				font-size: 14px;
				font-weight: bold;
			}
		}
		.sortable_elements {
			max-height:none;
			li {
				position: relative;
			    display: block;
			    border: 1px solid #000;
			    cursor: move;
			    color: #222222;
			    margin: 3px 3px 3px 0;
			    padding: 4px;
			    width: auto;
			    min-width: 250px;
			    height:auto;
			    overflow: auto;
			    font-size: 11px;
			    text-align: left;
			    border: 1px solid $p_border;
			    background: $p_bg;
			    padding: 0 30px 0 10px;
			    min-height: 40px;
				span.name {
					float: left;
					font-size:13px;
					font-weight:bold;
					margin-top: 6px;
					margin-right: 10px;
					margin-bottom: 6px;
				}
				a.edit {
					line-height: 31px;
				}
				.fields {
					float:left;
					clear:both;
					border-top:1px solid $p_border;
					.field-container {
						margin: 5px 5px 5px 0;
						float:left;
						input[type="text"] {
						float: left;
						width:80px;
						}
						input[type="checkbox"] {
						float: left;
						width:20px;
						}
						.wlabel {
							float: left;
							margin-top:1px;
						}
					}
				}
			}
		}
	}
	.statuses_added {
		border-right: 1px solid $p_border;
		padding-right:28px;
	}
	.pointer {
		cursor:pointer;
	}
	.showhide {
		float: right;
		padding-right:5px;
	}
	.expcol {
		font-size: 10px;
	}
}

// ERROR HINTS

#info {
	position:absolute;
	top:168px;
	width:1180px;
	margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
	z-index: 100;
	left: calc(50% - 590px);
	&.i-nav {
		position:fixed;
		top:45px;
		width:1180px;
		z-index: 100;
		left: calc(50% - 590px);
	}
	.hint-title {
		font-size: 16px;
		padding-bottom: 10px;
	}
	.hint-booking-changes {
		ul > li {
			font-weight:normal;
		}
	}
}

div#hint-info {
  	.hint-info-row {
    	padding: 7.5px;
    	width: 100%;
  	}
  	p {
    	margin: 0;
  	}
  	.hint-info-row ul {
    	padding-left: 10px;
    	list-style: none;
    	font-weight: bold;
    	font-size: 13px;
    	margin-bottom: 5px;
    	li {
      		margin: 5px 0;
    	}
  	}
	div {
	    &.success {
	    	color: #5A801B;
    		background-color: #dff0d8;
    		border: 1px solid #d6e9c6;
    		div.close {
      			color: #5A801B;
      		}
      		.time {
      			color: #5A801B;
      		}
	    }
	    &.success2 {
	    	color: #5A801B;
    		div.close {
      			color: #5A801B;
      		}
      		.time {
      			color: #5A801B;
      		}
	    }
	    &.error {
	      	border: 1px solid #ebccd1;
	      	background: #f2dede;
	      	color: #CC0000;
      		div.close {
      			color: #CC0000;
      		}
      		.time {
      			color: #CC0000;
      		}
	    }
	    &.error2 {
	      	color: #CC0000;
	      	div.close {
      			color: #CC0000;
      		}
      		.time {
      			color: #CC0000;
      		}
	    }
	    &.notice {
	      	color: #4985B2;
    		background: #d9edf7;
    		border: 1px solid #bce8f1;
    		div.close {
      			color: #4985B2;
      		}
      		.time {
      			color: #4985B2;
      		}
	    }
	    &.notice2 {
	      	color: #4985B2;
	      	div.close {
      			color: #4985B2;
      		}
      		.time {
      			color: #4985B2;
      		}
	    }
	    &.important {
	      	color: #F59747;
    		background: #FFE3CC;
    		border: 1px solid #FFDDC2;
    		div.close {
      			color: #F59747;
      		}
      		.time {
      			color: #F59747;
      		}
	    }
	    &.hint-info-row {
	      	position: relative;
	    }
	    &.message-box {
	    	div.text {
	    		width: calc(100% - 70px);
	    	}
	      	div.close {
	        	position: absolute;
	        		top: 10px;
	        		right: 16px;
	        		width: 20px;
	        		border-radius:100%;
	        		text-align: center;
	        		background: rgba(255, 255, 255, 0.8);
	        		line-height: 18px;
	        		padding: 0 0 1px 0;
	        		font-size: 10px;
	        		text-shadow: none;
	        		height: 20px;
	        		opacity: 0.5;
	      	}
	    	.time {
	        	width: 50px;
	        	margin-right: 10px;
	        	font-size: 14px;
	        	position: absolute;
	        	right: 10px;
	        	top: 2px;
	      	}
	    }
  	}
}

// AJAX Loading...

.loader-wrap {
	width:100%;
	height:100%;
	background: rgba(255,255,255,0.5);
	position:absolute;
	top:0;
	left:0;
	z-index: 98;
}
.loader {
	width:50px;
	height:50px;
	position:absolute;
	z-index: 99;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	background: $url_37 rgba(0,0,0,0.5) no-repeat;
	background-size: 25px 25px;
	background-position: center center;
}

// ------------------------ LOAD BUTTON --------------------------//

.load {
    background: $main_color_vivid $url_37 no-repeat scroll left 8px center / 12px auto!important;
}

// colors

.green {
	color: $yes!important;
}

.red {
	color: $no!important;
}

.orange {
	color: $main_color_vivid;
}

// validacja

.error input {
    border: 1px solid $no;
}
.error-message {
    color: $no;
}


// MESSAGES
.message {
	padding: 10px;
	&.message-alert {
    	background: #FFE3CC;
    	color: $main_font;
	}
	&.message-success {
    	background-color: #dff0d8;
    	color: $main_font;
	}
	&.message-error {
		background: #f2dede;
		color: $main_font;
	}
	&.message-notice {
    	background: #d9edf7;
    	color: $main_font;
	}
	p {
		margin:0;
	}		




}