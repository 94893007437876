.ui-dialog {
	padding: 0!important;
	border:0;
	border-radius:0;
	&.border {
		border: 1px solid $color_fuscous_gray_approx;
	}
}

.ui-dialog-titlebar {
	background: $color_fuscous_gray_approx;
	color: $white;
	font-weight: bold;
	font-size: 15px;
	padding:20px!important;
	border:0;
	border-radius: 0;
	.ui-dialog-title {
		width: 100%;
		text-align: center;
		span.lm {
			color: $color_carrot_orange_approx;
		}
	}
	.ui-dialog-titlebar-close {
		border-radius: 0;
		border: 0;
		background: $url_102 no-repeat;
		background-size: 10px;
		background-position: center;
		&:hover {
			-webkit-animation:spin 0.2s linear;
		    -moz-animation:spin 0.2s linear;
		    animation:spin 0.2s linear;
			@-moz-keyframes spin { 100% { -moz-transform: rotate(90deg); } }
			@-webkit-keyframes spin { 100% { -webkit-transform: rotate(90deg); } }
			@keyframes spin { 100% { -webkit-transform: rotate(90deg); transform:rotate(90deg); } }
		}
		.ui-button-icon-primary {
			display: none;
		}
	}
}

.ui-dialog-content {
	padding:0!important;
	background: $color_wild_sand_approx;
	//height: auto!important;
	//max-height: 400px!important;
}

.ui-dialog-buttonpane {
	background: $dialog_buttonset;
	width: 100%;
	padding: 0;
	.ui-dialog-buttonset {
		float: left;
		.button-disabled, .button-enable {
			float: left;
			margin: 10px 0 10px 10px;
			height: 40px;
			button {
				margin:0;
			}
		}
	}
}

.lfoot {
	height: 50px;
	width: 100%;
	float: left;
	background: $url_62 $color_alabaster_approx no-repeat;
	background-position: center;
}

#login_form, #registration_form, #help_info {
	display: none;
	padding: 15px;

	h4 {
		padding-bottom: 10px;
	}

	input {
		width:100%;
	}

	input[type="text"], input[type="password"] {
		margin-bottom: 8px;
	}

	input[type="submit"] {
		margin: 26px 0 8px 0;
		font-size: 15px;
		font-weight: 400;
	}

	a.forgot_password {
		font-size: 12px;
		color: $color_dove_gray_approx;
		&:hover {
			color: $color_fuscous_gray_approx;
		}
	}
	hr {
		//margin: 5px 0 10px 0;
		//margin:5px 0;
		&.dashed {
			border-top: 1px dashed $color_mercury_approx;
		}
	}
	.facebook-login {
		margin-bottom: 5px;
		display: block;
		/* margin-left: 0 auto;
		margin-right: 0 auto;
    	text-align: center; */
	}
	.google-login {
		margin-bottom: 5px;
		display: block;
		/* margin-left: 0 auto;
		margin-right: 0 auto;
    	text-align: center; */
	}
	.user_gender, .user_legal_status {
		text-align: center;
		label {
			margin: 0 10px;
			font-weight: normal;
			font-size: 12px;
		}
	}
	.register {
		width:100%;
		background: $color_fuscous_gray_approx;
		color: $white;
		display: block;
		font-size: 15px;
		font-weight: 400;
		border: 0;
		border-radius: 4px;
		height: 46px;
		&:hover {
			background: $black;
			color: $white;	
		}
	}
	textarea[name="rules"] {
		display: none;
	}

	/* nieuzywane */
	button.btn-facebook {
		background: #3b5998;
		color: $white;
	}
	button.btn-google {
		background: #c23321;
		color: $white;
	}
}

#faq_dialog {
	padding: 20px;
	float: left;
	width: 100%;
	.help-col {
		text-align: center;
		margin-top: 20px;
		font-size: 12px;
		ol {
			text-align: left;
		}
		h5 {
			margin-bottom: 15px;
		}
	}
	.help-link {
		float: left;
		width: 100%;
		text-align: center;
		background: $color_vermilion_approx;
		color: $white;
		padding: 15px 0 12px 0;
	}
	ul {
		float: left;
		width: 100%;
		margin-top: 20px;
		margin-bottom: 10px;
		list-style-type: none;
    	padding-left: 0;
		li {
			margin-bottom: 5px;
			h3 {
				cursor: pointer;
				margin: 0;
				font-size: 13px;
				font-weight: bold;
				border: 1px solid $color_mercury_approx;
				background: $color_gallery_approx;
				padding:8px 10px;
			}
			.answear {
				display: none;
				padding:15px;
				ul.list-round {
					float: none;
				}
			}
			&:last-child {
				margin-bottom:0;
			}
		}
	}
	a {
		color: $color_vermilion_approx;
		&.link {
			margin-bottom:3px;
			float: left;
			width: 100%;
			color: $white;
			font-size: 14px;
		}
	}
}

#rules_dialog {
	padding: 20px;
}

#contact_dialog {
	padding: 20px;
	float: left;
	width: 100%;
	.contact-col {
		text-align: center;
		margin-top: 30px;
		font-size: 12px;
		margin-bottom: 15px;
		ol {
			text-align: left;
		}
		h5 {
			margin-bottom: 15px;
		}
	}
	.account-number {
		background: $color_alabaster_approx;
		float: left;
		width: 100%;
		padding: 8px 16px;
		margin:20px 0;
		font-size: 14px;
		padding-bottom: 16px;
	}

	.contact-link {
		float: left;
		width: 100%;
		text-align: center;
		background: $color_vermilion_approx;
		color: $white;
		padding: 15px 0 12px 0;
	}
	h4 {
		font-size: 16px;
	}
	a {
		color: $color_vermilion_approx;
		&.link {
			margin-bottom:3px;
			float: left;
			width: 100%;
			color: $white;
			font-size: 14px;
		}
	}
}
#share_dialog {
	padding: 20px;
	table {
		width: 100%;
		tr {
			height: 50px;
			border-bottom: 1px solid $color_mercury_approx;
			line-height: 50px;
			&:last-child {
				border:0;
			}
			td {
				padding:5px 10px;
				&:first-child {
					background: $color_gallery_approx;
					text-align: center;
				}
			}
		}
	}
	button.copy {
		color: $white;
		background: $color_vermilion_approx;
		font-size: 14px;
	}
}