input[type="text"], input[type="password"], input[type="time"], input[type="submit"] {
 	height: 46px;
 	border-radius: 5px;
 	font-size: 13px;
 	padding: 7px 8px;
 	color: $mine_shaft;
 	background: $white;
 	font-family: $font_0, $font_1;
 	-webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.15), 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.15), 0 1px 1px rgba(0,0,0,0.075);
    border: 1px solid $color_gray_nurse_approx;
}

input[type="text"], input[type="password"], input[type="time"] {
	background: $white;
 	color: $mine_shaft;
}

input[type="submit"] {
	background: $color_vermilion_approx;
	color: $white;
	&:hover {
		background: $color_vermilion_approx_hover;
	}
}

/* input {
	box-shadow:none!important;
} */

input[type="text"][disabled], input.input_disabled {
	cursor: not-allowed;
	background-color: #eee;
	opacity: 1;
}

select {
   	-webkit-appearance: none;
   	-moz-appearance:    none;
   	appearance:         none;
   	font-family: $font_0, $font_1;
   	//border: 1px solid $border;
   	border-radius: 0;
   	padding: 0 25px 0 6px;
	//background: $white $url_10 no-repeat;
	background-size: 8px 5px;
	background-position: top 14px right 10px;
	background-position-x: 95%;
	background-position-y: 14px;
   	//color: $b_font_color;
   	width: 100%;
   	height: 46px
   	/* &:focus {
	  	box-shadow: none!important;
	  	outline: 0 none!important;
   	} */
}

select[disabled] {
	cursor: not-allowed;
	background-color: #eee;
	opacity: 1;
}
select::-ms-expand {
  display: none;
}

.bootstrap-select {
	width:100%!important;
	.btn-default {
		height: 46px;
		border-radius: 5px;
		background: $white;
		border: 1px solid $color_gray_nurse_approx;
		font-size: 14px;
		font-weight: bold;
		color: $mine_shaft;
	}
}

textarea {
	border: 1px solid $color_gray_nurse_approx!important;
	width:100%;
	//padding: 6px 12px;
	//background: $white;
	//color: $b_font_color;
	font-family: $font_0, $font_1!important;
	//margin:3px 0;
	resize: none;
}

.field-container {
	&.error {
		input[type="text"], input[type="password"] {
			background: $color_pippin_approx;
		}
	}
	.error-message {
		display: block;
	    margin-bottom: 10px;
	    font-size: 12px;
	    text-align: center;
	    color: $red;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    //margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}