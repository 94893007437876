/* LOGIN STATIC */

.registration-form-static, .user-login-static {
	input {
		width: 100%;
	}
}

.user-login-static {
	float: left;
	width: 100%;
  	padding: 50px 0;
  	.login {
  		border-right: 1px solid $p_border;
  		width: 50%;
  		float: left;
  		padding: 0 20px;
  	}
  	.registration {
  		float: left;
  		width: 50%;
  		padding: 0 20px;
  		a {
  			background: $color_vermilion_approx;
		    padding: 0 20px;
		    line-height: 46px;
		    border-radius: 5px;
		    color: $white;
		    font-size: 14px;
		    float: left;
  		}
  	}
	.information {
		text-align: center;
		font-size: 16px;
		margin-top: 40px;
		padding-bottom: 20px;
		}
	.plogin-start {
		width: 100%;
		float: left;
		margin-top: 8px;
		label {
			font-weight: normal;
			margin-bottom: 0;
		}
		input {
			float: left;
		}
		a.forgot { 
			line-height: 46px; 
			margin-top: 10px;
			margin-left: 20px;
			float: left; 
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.social-login {
		float: left;
		width: 100%;
		border-top: 1px solid $p_border;
		margin-top: 20px;
		padding: 20px;
		div {
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 413px;
			a.google-login {
				float: left; 
				width: 183px;
			}
			a.facebook-login {
				float: left;
				margin-right:10px;
				width: 220px;
			}
		}
	}
}

.login_problem_dialog {
	.login-help_wrapper {
		padding: 20px;
		table {
			width: 100%;
			tr {
				border-bottom: 1px solid $p_border;
				&:last-child {
					border:0;
				}
				td {
					padding:5px 10px;
					&:last-child {
						text-align: right;
					}
					.btn {
						background: $main_color_vivid;
						color: $white;
					}
				}
			}
		}
		button.copy {
			color: $white;
			background: $main_color_vivid;
			font-size: 14px;
		}
	}
}

.forgot-pass {
	padding-top: 100px;
	text-align:center;
	h5 {
		padding-top: 30px;
		font-size: 16px;
	}
	table.taccount {
		margin-left: auto;
		margin-right: auto;
		input[name="user_email"] {
			width:300px!important;
		}
  	}
} 

.rules-info {
	margin-top:30px;
	margin-bottom:30px;	
}

// ------------------- REGISTRATION FORM ---------------------//

.registration-form-static {
	margin:20px 0;
	.line-item {
		float: left;
		width: 100%;
		margin-bottom: 10px;
		.label {
			font-size: 12px;
			color: $main_font;
			line-height: 20px;
			font-weight: normal;
			text-align: center;
			width: 100%;
		}
	}
	.user_legal_status, .gender {
		label {
			margin: 10px;
		}
		input[type="radio"] {
			margin-right: 4px;
		}
	}
	.rules {
		textarea[name="rules"] {
			width: 100%;
			height: 307px;
			display: none;
		}
		.rules-window {
			width: 100%;
			height: 307px;
			background: $white;
			border: 1px solid $border;
			overflow: auto;
			padding: 10px;	
		}
	}
	input.submit {
		width: auto;
	}
}