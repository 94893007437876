//colors
$main_color_vivid: #ff4e00;
$main_color_vivid_hover: #da4403;
$main_font: #696969;
$second_font: #222222;

$color_aqua_haze_approx: #ebf4f7;
$early_dawn: #fff9e6;
$color_aqua_haze_approx: #ebf4f7;
$color_nobel_approx: #b6b6b6;
$color_mercury_approx: #e3e3e3;

//top header
$header_top_bg: #bdbdbd;
$header_top_font: #333333;
$header_top_line: #b6b6b6;

//menu
$menu_bg: #696969;

//portlets
$p_header_new: #f2f2f2;
$p_header: #d7d7d7;
$p_header-top: #959595;
$p_bg: #f8f8f8;
$p_border: #e3e3e3;

//tables
$table_font: #5b5b5b;
$table_header: #eaeaea;
$tr_hover: #f3f3f3;

//forms
$border: #d7d7d7;
$border_focus: #bdbdbd;
$b_font_color:#555555; 

//action
//$action_bg: #aaaaaa; stare
$action_bg: #ff4e00;
$action_font_hover: #333333;

//datepicker
$dp_header: #242424;
$dp_header_font: #ffffff;
$dp_week_font: #8592a3;
$dp_border: #8592a3;

//buttons
$yes: #39b54a;
$no: #dd1818;

//basic colors
$black: #000;
$white: #fff;


//fonts
$font_opensans: "Open Sans";
$font_backup: "sans-serif";





$color_alabaster_approx: #f9f9f9;
//$color_mercury_approx: #e3e3e3;
$color_vermilion_approx: #ff4e00;
$black_10: rgba(0,0,0,0.1);
$white_10: rgba(255,255,255,0.1);
$white: #fff;
$color_fuscous_gray_approx: #525252;
//$color_dove_gray_approx: #696969;
$color_atlantis_approx: #a6c040;
$mine_shaft: #323232;
//$color_gray_nurse_approx: #eaeaea;
$color_celeste_approx: #ccc;
$color_silver_chalice_approx: #aaa;
//$color_shark_approx: #242424;
$color_alto_approx: #dadada;
$color_suva_gray_approx: #888;
$color_grenadier_approx: #da4402;
$color_persian_red_approx: #cd3d27;
$color_trinidad_approx: #e34c0a;
//$color_mine_shaft_approx: #333333;
//$color_silver_approx: #bdbdbd;
$black: #000;
//$color_nobel_approx: #b6b6b6;
//$color_carrot_orange_approx: #f68526;
$color_storm_dust_approx: #646464;
$color_quill_gray_approx: #d3d2d2;
$color_limeade_approx: #6e9e00;
$color_cardinal_approx: #cc2738;
//$color_desert_storm_approx: #f8f8f8;
$color_iron_approx: #d7d7d7;
//$color_chicago_approx: #5b5b5b;
//$color_concrete_approx: #f3f3f3;
$color_chateau_green_approx: #39b54a;
$color_monza_approx: #dd1818;
$color_orange_approx: #f36b21;
$red: red;
$concrete: #f2f2f2;
$color_mountain_mist_approx: #939393;
$color_pumice_approx: #c6c6c6;
//$color_log_cabin_approx: #222222;
$color_cloud_approx: #c4c4c4;
$early_dawn: #fff9e6;
$color_saffron_mango_approx: #ffbe57;
$color_gravel_approx: #4c4c4c;
$color_regent_gray_approx: #8592a3;
$color_mid_gray_approx: #63626a;
$color_gallery_approx: #f0f0f0;
$color_glacier_approx: #75a3c7;
$color_cornflower_approx: #90bee3;
$color_burning_orange_approx: #ff763a;
$black_0: rgba(0,0,0,0.0);
$color_koromiko_approx: #ffc261;
$color_travertine_approx: #fdfce8;
$blue:  blue;
$color_silver_sand_approx: #c2c2c2;
$color_morocco_brown_approx: #412001;
$color_peru_tan_approx: #883000;
$color_vida_loca_approx: #5a801b;
$color_guardsman_red_approx: #cc0000;
$color_steel_blue_approx: #4985b2;
$important_alert: #F5CEAE;
$pastel_important_alert: #f9e5d4;