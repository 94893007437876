footer {
    background: $color_gallery_approx;
    padding: 50px 0;
    width: 100%;
    position: relative;
    clear: both;
    //margin-top: 10px;
    &.panel {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color_gallery_approx!important;
        margin-bottom: 0!important;
    }
    h4 {
    	font-size: 16px;
    	margin-bottom: 15px;
    }
    ul {
    	li {
    		font-size: 12px;
    		line-height: 20px;
    		a {
    			color:$color_dove_gray_approx;
    		}
    	}
    }
   	.subfooter {
	    height: 40px;
	    background: #e3e3e3;
	    margin-top: 40px;
	    text-align: center;
	    font-size: 11px;
	    line-height: 40px;
	}
    &.home {
        position: absolute;
        bottom: 0;
    }
}

/* Login Info */

.login-info {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 270px;
    background: rgba(234,234,234, .8);
    z-index: 20;
    width: 100%;
    text-align: center;
    border-top: 1px solid $color_gray_nurse_approx;
    .wrapper {
        width:100%;
        height: 100%;
        position: relative;
        padding: 20px;
    }
    &__close {
        position:absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    h3 {
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 22px;
    }
    p {
        font-size: 16px;
    }
    button.login-btn {
        width: 150px;
        color: #fff;
        height: 39px;
        font-weight: 600;
        font-size: 14px;
        background: $color_grenadier_approx $url_3 no-repeat;
        background-size: 18px 18px;
        background-position: right 12px top 11px;
        background-position-x: 90%;
        background-position-y: 50%;
        text-align: left;
        padding-left: 30px;
        border-radius: 4px;
    }
    button.register-btn {
        width: 180px;
        color: #fff;
        height: 39px;
        font-weight: 600;
        font-size: 14px;
        background: $mine_shaft;
        text-align: center;
        border-radius: 4px;
    }
}