/* NAV */

nav {
	height: 85px; 
	background: #ff4e00;
	z-index:12!important;

	-webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;

	&.smaller {
		height: 65px;

		.logo {
			a {
				img.logo {
					width: 150px;
					height: 52px;
				}
			}
		}

		.menu {
			li {
				a {
					line-height: 65px;
					height: 65px;
				}
			}
		}

		.login {
			margin-top: 6px;
		}
	}

	.container { 
		padding-left: 0; 
		padding-right: 0; 
	}

	.top {
	    font-weight: bold;
	    float: right;
	}

	.logo {
		a {
			img.logo { 
				padding-left: 0;
				margin:7px 0 6px 0;
				width: 210px;
				height: 71px;

				-webkit-transition: height 0.6s;
			    -moz-transition: height 0.6s;
			    -ms-transition: height 0.6s;
			    -o-transition: height 0.6s;
			    transition: height 0.6s;
			}
		}
	}

	.menu {
		float: left;
		margin:0;
		padding:0;
		margin-right: 10px;
		color: $white;
		font-size: 14px;
		font-weight: 600;
		li {
			padding:0;
			a {
				color: $white;
				line-height: 85px;
				height: 85px;
				display: block;
				padding: 0 10px;

				-webkit-transition: height 0.3s;
			    -moz-transition: height 0.3s;
			    -ms-transition: height 0.3s;
			    -o-transition: height 0.3s;
			    transition: height 0.3s;
				&.active {
					background: $color_vermilion_approx_hover;
				}
			}
		}
	}
	
	.login { 
		width: 160px; 
		float: right;
		margin-right: 10px;
		margin-top: 12px;
		button.login {
		    width: 100%;;
		    color: #fff;
		    height: 39px;
		    font-weight: 600;
		    font-size: 14px;
		    background: $color_grenadier_approx $url_3 no-repeat;
		    background-size: 18px 18px;
		    background-position: right 12px top 11px;
		    background-position-x: 90%;
		    background-position-y: 50%;
		    text-align: left;
		    padding-left: 30px;
		    border-radius: 4px;
		}
	}
}